import React, {useState, useEffect} from 'react';
import {
    ButtonGroup, Dialog, DialogContent,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography
} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import UserService from '../../services/user.service';
import Table from '@mui/material/Table';
import {Link} from 'react-router-dom';
import Button from "@mui/material/Button";
import VOCSETLayout from '../../views/VOCSETLayout';
import {dropdownItems} from '../../views/dropdownItems';
import {MoonLoader} from "react-spinners";
import {User} from "../../types/APITypes";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";

createTheme();
const UserDashboard = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [error, setError] = useState<string>('');
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(true);
    const [privileges, setPrivileges] = useState<string[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [action, setAction] = useState<'activate' | 'delete' | 'error' | null>(null);

    const resize = () => {
        setOpen(window.innerWidth >= 760);
    };
    useEffect(() => {
        // @ts-ignore
        window.addEventListener('resize', resize.bind(this));
        resize();
    }, []);

    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'Script error.') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                )
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                )
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        })
    }, [])

    useEffect(() => {

        const storedPrivileges = localStorage.getObj('privileges');
        if (storedPrivileges) {
            setPrivileges(storedPrivileges);
        }
        const fetchUsers = async () => {
            try {
                const response = await UserService.getUsers();
                if (response) {
                    setLoading(false)
                }
                const fetchedUsers = response.result;
                if (fetchedUsers && fetchedUsers.length > 0) {
                    setUsers(fetchedUsers);
                } else {
                    setError('No users available.');
                }
            } catch (error) {
                // @ts-ignore
                console.error('Error fetching users:', error.response ? error.response.data : error);
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching users. Please try again.');
            }
        };

        fetchUsers();
    }, []);

    let dialogContent = '';
    if (action === 'activate') {
        dialogContent = 'Activation complete';
    } else if (action === 'delete') {
        dialogContent = 'Deactivation complete';
    } else if (action === 'error') {
        dialogContent = error;
    }

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleActivation = async (user: User) => {
        try {

            const updatedUserData = {
                ...user,
                active: true,
            };

            await UserService.updateUser(updatedUserData);
            setAction('activate');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error: any) {
            console.error('Error activating user:', error);
            const errorMessage = error?.response?.data?.result?.[0]?.message || 'Error activating user. Please try again.';
            setError(errorMessage);
            setAction('error');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        }
    };

    const handleDelete = async (username: string) => {
        try {
            await UserService.deleteUser(username);
            setAction('delete');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error: any) {
            console.error('Error deleting user:', error);
            const errorMessage = error?.response?.data?.result?.[0]?.message || 'Error deleting user. Please try again.';
            setError(errorMessage);
            setAction('error');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        }
    };

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                {(privileges.includes('CREATE_ANY_USER') || privileges.includes('CREATE_OWN_USER')) && (
                    <ButtonGroup>
                        <Button aria-label="New User Button" variant="contained" component={Link} to="/CreateUser"
                                startIcon={<AddIcon/>}>New User</Button>
                    </ButtonGroup>
                )}
                {loading ? (
                    <MoonLoader color="#282c34"/>
                ) : (
                    users.length === 0 ? (
                        <p>No users found.</p>
                    ) : (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Username</TableCell>
                                        <TableCell>First Name</TableCell>
                                        <TableCell>Last Name</TableCell>
                                        <TableCell>Roles</TableCell>
                                        <TableCell>Company</TableCell>
                                        {(privileges.includes('WRITE_ANY_USER') || privileges.includes('WRITE_OWN_USER')) && (
                                            <TableCell>Actions</TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((user) => (
                                        <TableRow key={user.id} style={{backgroundColor: user.active ? '' : '#f5f5f5'}}>
                                            <TableCell>{user.username}</TableCell>
                                            <TableCell>{user.firstName}</TableCell>
                                            <TableCell>{user.lastName}</TableCell>
                                            <TableCell>{user.roles.length > 0 ? user.roles[0].name : ""}</TableCell>
                                            <TableCell>{user.ownerName}</TableCell>
                                            {(privileges.includes('WRITE_ANY_USER') || privileges.includes('WRITE_OWN_USER')) && (
                                                <TableCell>
                                                    <ButtonGroup className="dash-button-group">
                                                        <Link to={`/EditUser`} state={user}>
                                                            <Button className="dash-button" startIcon={<EditIcon/>}/>
                                                        </Link>
                                                        {user.active ? (
                                                            <Button
                                                                className="dash-button"
                                                                startIcon={<DeleteIcon/>}
                                                                onClick={() => handleDelete(user.username)}
                                                            />
                                                        ) : (
                                                            <Button
                                                                className="dash-button"
                                                                startIcon={<DoneIcon/>}
                                                                onClick={() => handleActivation(user)}
                                                            >
                                                            </Button>
                                                        )}
                                                    </ButtonGroup>
                                                </TableCell>


                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                )}
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">{dialogContent}</Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default UserDashboard;


