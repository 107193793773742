import React, { useState } from 'react';
import { Box, Button, Container, CssBaseline, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RegisterService from '../services/register.service';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const defaultTheme = createTheme();

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [registrationError, setRegistrationError] = useState('');
    const [registrationSuccess, setRegistrationSuccess] = useState(false); // State for registration success
    const navigate = useNavigate();

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        // Email Validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email');
            return;
        } else {
            setEmailError('');
        }

        // Password Validation
        if (password !== confirmPassword) {
            setPasswordError('Passwords do not match');
            return;
        } else {
            setPasswordError('');
        }
        if (password.length < 6) {
            setPasswordError('Password must be at least 6 characters');
            return;
        } else {
            setPasswordError('');
        }

        try {
            await RegisterService.register(email, password);
            setRegistrationSuccess(true); // Set registration success
        } catch (error: any) {
            console.error('Error during registration:', error);
            if (error.response && error.response.status === 400) {
                setRegistrationError('User already exists. Please choose a different email.');
            } else {
                setRegistrationError('Error during registration. Please try again.');
            }
        }
    };

    const handleCloseDialog = () => {
        setRegistrationSuccess(false); // Close the pop-up message
        navigate('/'); // Navigate to the login screen
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            value={email}
                            autoFocus
                            error={emailError !== ''}
                            helperText={emailError}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            value={password}
                            error={passwordError !== ''}
                            helperText={passwordError}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            id="confirmPassword"
                            value={confirmPassword}
                            error={confirmPassword !== password || passwordError !== ''}
                            helperText={confirmPassword !== password ? 'Passwords do not match' : ''}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign Up
                        </Button>
                        {registrationError && (
                            <Typography variant="body2" color="error" align="center">
                                {registrationError}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Container>
            <Dialog open={registrationSuccess} onClose={handleCloseDialog}>
                <DialogTitle>Registration Successful</DialogTitle>
                <DialogContent>
                    <Typography>Your verification email has been sent. Please check your mailbox.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default Register;
