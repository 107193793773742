import {handleRequestError} from '../errorHandler';
import authHeader from './auth.header';
import axios from 'axios';

const ADMIN_SERVICE_URL = '/api/admin';
const ctype = {'Content-Type': 'application/json'}

class AssociationService {
    async getAssociations() {
        try {
            const response = await axios.get(`${ADMIN_SERVICE_URL}/association`,
                {
                    headers: {...authHeader(), ...ctype}});
            return response.data.result;
        } catch (error) {
            console.log('ASC FETCH ERROR ', error)
        }
    }

    async createAssociations(associations: any) {
        try {
            const response = await axios.post(`${ADMIN_SERVICE_URL}/association`, associations, {
                headers: {...authHeader(), ...ctype}
            });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

    async deleteAssociations(associationIds: string[]) {
        try {
            const response = await axios.delete(`${ADMIN_SERVICE_URL}/association`, {
                data: associationIds,
                headers: {...authHeader(), ...ctype}
            });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

}

export default new AssociationService();
