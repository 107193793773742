import React, {ChangeEvent, useEffect, useState} from "react";
import * as XLSX from "xlsx";
import "@infinite-table/infinite-react/index.css";
import '../../index.css';
import tradeService from "../../services/trade.service";
import VOCSETLayout from "../../views/VOCSETLayout";
import {dropdownItems} from "../../views/dropdownItems";
import {Trade, UnvalidatedTrade} from '../../types/APITypes';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import {styled} from '@mui/material/styles';
import {
    InfiniteTable,
    DataSource,
    InfiniteTablePropColumns,
    DataSourcePropCellSelection_MultiCell,
    InfiniteTablePropRowStyle
} from '@infinite-table/infinite-react';
import {
    Button,
    Tabs,
    Tab,
    Dialog,
    DialogContent,
    Typography,
    DialogTitle,
    DialogContentText,
    DialogActions,
    MenuItem,
    Select,
    ButtonGroup
} from "@mui/material";
import {MoonLoader} from "react-spinners";
import AliasService from '../../services/alias.service';
import {formatVocsetTrade, vocsetColumns} from './conf/vocsetColumns';
import {formatCmeTrade} from './conf/cmeColumns';
import {formatIceTrade} from './conf/iceColumns';
// @ts-ignore
import {CSVLink} from "react-csv";
import {CloudDownload} from "@mui/icons-material";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

// @ts-ignore
const tradeColumns: InfiniteTablePropColumns<Trade> = vocsetColumns;
// @ts-ignore
let unvalidatedTradeColumns: InfiniteTablePropColumns<UnvalidatedTrade> = vocsetColumns;

const TradeDashboard = () => {
    const [trades, setTrades] = useState<Trade[]>([]);
    const [unvalidatedTrades, setUnvalidatedTrades] = useState<UnvalidatedTrade[]>([]);
    const [originalTrades, setOriginalTrades] = useState<UnvalidatedTrade[]>([]);
    const [loading, setLoading] = useState(false);
    const [imported, setImported] = useState(false);
    const [openTradeConfDialog, setTradeConfDialog] = useState(false);
    const [openAliasConfDialog, setOpenAliasConfDialog] = useState(false);
    const [selectedBlotter, setSelectedBlotter] = useState<string>((trades.length > 0 ) ? 'trades':'import');
    const [errorTradeIds, setErrorTradeIds] = useState<string[]>([]);
    const [errorDetails, setErrorDetails] = useState<any[]>([]);
    const [cellSelection, setCellSelection] = useState<DataSourcePropCellSelection_MultiCell>({
        defaultSelection: false,
        selectedCells: [],
    });
    const [successCount, setSuccessCount] = useState(0);
    const [errorCount, setErrorCount] = useState(0);
    const [entityName, setEntityName] = useState('');
    const [assetClass, setAssetClass] = useState('');
    const [alias, setAlias] = useState('');
    const [value, setValue] = useState('');
    const [columnField, setColumnField] = useState('');
    const [valueOptions, setValueOptions] = useState([]);
    const [contractOptions, setContractOptions] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [aliasError, setAliasError] = useState<string | null>(null);
    const [exchangeMic, setExchangeMic] = useState('');
    const [exchangeFormat, setExchangeFormat] = useState('');

    useEffect(() => {
        const fetchTrades = async () => {
            setLoading(true);
            try {
                const {result: fetchedTrades} = await tradeService.getTrades();
                setTrades(fetchedTrades || []);
            } catch (error) {
                console.error('Failed to fetch trades:', error);
            } finally {
                setLoading(false);
            }
        };

        if (selectedBlotter === 'trades') fetchTrades();
    }, [selectedBlotter]);

    const handleFile = async (ev: ChangeEvent<HTMLInputElement>): Promise<void> => {
        const file = await ev.target.files?.[0]?.arrayBuffer();
        if (!file) return;

        setErrorTradeIds([]);
        setCellSelection({defaultSelection: false, selectedCells: []});
        await readExcelSheet(file)

        ev.target.value = '';
    };

    const readExcelSheet = async (file: ArrayBuffer): Promise<void> => {
        const data = XLSX.read(file, {cellText: false, cellDates: true});
        const ws = data.Sheets[data.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(ws, {header: 1});
        // @ts-ignore
        const tradeIdRowIndex = jsonData.findIndex(row => typeof row[0] === 'string' && row[0] === 'ICE ID');
        if (tradeIdRowIndex !== -1) {
            const startRowNumber = tradeIdRowIndex + 1;
            const lastRowNumber = jsonData.length;
            ws['!ref'] = `A${startRowNumber}:AG${lastRowNumber}`;
        } else {
            console.log("ICE ID cell not found.");
        }

        const trimWhitespace = (obj: Partial<UnvalidatedTrade>): Partial<UnvalidatedTrade> => {
            const trimmedObj: Partial<UnvalidatedTrade> = {};
            for (const key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    // @ts-ignore
                    const value = obj[key];
                    // @ts-ignore
                    trimmedObj[key] = typeof value === 'string' ? value.trim() : value;
                }
            }
            return trimmedObj;
        };

        let trades: Partial<UnvalidatedTrade>[] = XLSX.utils.sheet_to_json<Partial<UnvalidatedTrade>>(ws, {
            raw: false,
            dateNF: 'yyyy-mm-dd',
            defval: null,
            skipHidden: true
        }).map(trimWhitespace);

        console.log('TRADES ', trades)

        let cmeTrades: Partial<UnvalidatedTrade>[] = XLSX.utils.sheet_to_json<Partial<UnvalidatedTrade>>(ws, {
            raw: true,
            defval: null,
            skipHidden: true
        }).map(trimWhitespace);

        const formatDateTime = (dateTime: string | undefined): string => {
            if (!dateTime) return '';
            const date = new Date(dateTime);
            return `${date.toISOString().split('T')[0]} ${date.toLocaleTimeString('en-GB', {hour12: false})}`;
        };

        let displayTrades: Partial<UnvalidatedTrade>[] = [];

        if (trades.length > 0) {
            const firstTrade = trades[0];

            if (firstTrade.hasOwnProperty('ICE ID')) {
                // @ts-ignore
                trades = trades.filter(trade => trade['Option'] !== 'uds');
                setExchangeFormat('Ice');
                trades = await Promise.all(trades.map(formatIceTrade));
            } else { // @ts-ignore
                if (firstTrade.hasOwnProperty('Source') && firstTrade['Source'] == 'CMED') {
                    console.log('CME FORMAT')
                    // @ts-ignore
                    cmeTrades = cmeTrades.filter(trade => trade['Cleared Contracts']);
                    if (cmeTrades.length === 0) {
                        console.log('invalid trades ');
                    }
                    setExchangeFormat('CME');
                    trades = await Promise.all(cmeTrades.map(formatCmeTrade));
                } else {
                    // @ts-ignore
                    trades = await Promise.all(trades.map(formatVocsetTrade));
                }
            }
        }

        const originalTrades = trades.map(trade => ({
            ...trade,
            assetClass: !trade['optionType'] && !trade['strike'] ? 'Future' : 'Option'
        }));

        displayTrades = trades.map(trade => ({
            ...trade,
            giveupTime: formatDateTime(trade['giveupTime']),
            executionTime: formatDateTime(trade['executionTime']),
        }));

        // @ts-ignore
        setOriginalTrades(originalTrades);
        // @ts-ignore
        setUnvalidatedTrades(displayTrades);

        setImported(true)
    };


    const handleSubmitTrades = async (event: {
        preventDefault: () => void
    }) => {
        event.preventDefault();
        try {
            const response = await tradeService.postNewTrades(originalTrades);
            const result = response.result;

            // @ts-ignore
            const successCount = result.filter(({status}) => status === 'OK').length;
            // @ts-ignore
            const errorCount = result.filter(({status}) => status === 'ERROR').length;

            if (errorCount === 0) {
                setImported(false);
            }

            setSuccessCount(successCount);
            setTradeConfDialog(true);

            // @ts-ignore
            if (result.filter(({status}) => status === 'ERROR')) {
                // @ts-ignore
                const fetchedErrors = result.filter(({status}) => status === 'ERROR');

                // find rows where the only error is tradeId
                // @ts-ignore
                const duplicateTrades = new Set(fetchedErrors.filter(({field}) => field === 'tradeId')
                    // @ts-ignore
                    .map(({id}) => id)
                    // @ts-ignore
                    .filter(id => fetchedErrors.filter(error => error.id === id).length === 1));

                const updatedUnvalidatedTrades = unvalidatedTrades.filter(trade => {
                    const fieldsWithErrors = fetchedErrors.filter((error: {
                        id: string;
                    }) => error.id === trade.tradeID);
                    return fieldsWithErrors.length > 0 && !(fieldsWithErrors.length === 1 && duplicateTrades.has(trade.tradeID));
                });

                setUnvalidatedTrades(updatedUnvalidatedTrades);

                // remove the errors where only trade id was the issue from the error list
                // @ts-ignore
                const filteredErrors = fetchedErrors.filter(({id, field}) =>
                    !(field === 'tradeId' && duplicateTrades.has(id))
                );

                // remove duplicate trades from the error count
                // @ts-ignore
                const reducedErrorCount = new Set(filteredErrors.filter(({status}) => status === 'ERROR').map(error => error.id)).size;

                if (reducedErrorCount === 0) {
                    setImported(false)
                }

                setErrorCount(reducedErrorCount)
                setErrorDetails(filteredErrors);
                // @ts-ignore
                const cells = fetchedErrors.map(error => [error.id.toString(), error.field]);
                setCellSelection({defaultSelection: false, selectedCells: cells});
                // @ts-ignore
                setErrorTradeIds(Array.from(new Set(fetchedErrors.map(error => error.id.toString()))));
            } else {
                const updatedUnvalidatedTrades = unvalidatedTrades.filter(trade =>
                    // @ts-ignore
                    !result.some(({id, status}) => id === trade.tradeID && status === 'OK')
                );
                setUnvalidatedTrades(updatedUnvalidatedTrades);
                setImported(false)
                setErrorDetails([]);
                setCellSelection({defaultSelection: false, selectedCells: []});
                setErrorTradeIds([]);
            }
        } catch (error) {
            console.error("Error submitting trades:", error);
        }
    };

    const handleCloseTradeConfDialog = () => {
        setTradeConfDialog(false);
        if (errorCount > 0) {
            setSelectedBlotter('import')
        } else {
            setSelectedBlotter('trades')
        }
    };

    const fetchAliasValues = async (entityName: string, exchangeMic?: string, assetClassFilter?: string, columnName?: string) => {
        try {
            let fetchedData;
            let roleType;

            console.log('exchangeMic:', exchangeMic, 'assetClassFilter:', assetClassFilter);


            if (entityName === 'Account' && columnName) {
                if (columnName === 'clearingAccount') {
                    roleType = 'ROLE_CLEARING_ACCOUNT'
                } else if (columnName === 'executingAccount') {
                    roleType = 'ROLE_EXECUTING_ACCOUNT'
                }
                console.log('entity name role', entityName, roleType)
                fetchedData = await AliasService.findAliasValues(entityName, roleType);
                console.log('account fetch ', fetchedData)
            } else {
                console.log(entityName)
                fetchedData = await AliasService.findAliasValues(entityName);
                console.log('contract fetch ', fetchedData)
            }
            let filteredData = fetchedData;
            let filteredMic: string | null = null;

            if (entityName === 'Contract') {
                console.log('fetched contract data ', fetchedData)
                filteredData = fetchedData.filter((item: {
                    exchangeMic: string;
                    assetClass: string
                }) =>
                    item.exchangeMic === exchangeMic && item.assetClass === assetClassFilter);

                console.log('filtered contract data ', filteredData)

                if (filteredData.length === 0) {
                    if (exchangeMic != null) {
                        filteredData = fetchedData.filter((item: {
                                micAlias: string[];
                                assetClass: string;
                            }) =>
                                item.micAlias.includes(exchangeMic) && item.assetClass === assetClassFilter
                        );
                    }
                }

                if (filteredData.length > 0) {
                    filteredMic = filteredData[0].exchangeMic;
                    // @ts-ignore
                    setExchangeMic(filteredMic)
                }

                const contractCodes = filteredData.map((item: {
                    contractCode: any
                }) => item.contractCode);
                setContractOptions(contractCodes);
            }

            setValueOptions(filteredData);
        } catch (error) {
            console.error('Error fetching alias values:', error);
            setError('Error fetching alias values. Please try again.');
        }
    };

    useEffect(() => {
        console.log("Error Details", errorDetails);
    }, [errorDetails]);

    const handleSubmitAlias = async (event: {
        preventDefault: () => void
    }) => {
        event.preventDefault();

        const entityMap: Record<string, string> = {
            'Contract': 'contractCode',
            'Account': 'accountName',
            'Exchange': 'micCode',
            'Executing Broker': 'companyShortName',
            'Clearing Broker': 'companyShortName',
            'Client': 'companyShortName',
        };

        const fieldMap: Record<string, string> = {
            'Contract': 'contractCode',
            'Account': '',
            'Exchange': 'mic',
            'Executing Broker': 'executingBroker',
            'Clearing Broker': 'clearingBroker',
            'Client': 'client',
        };

        let updatedEntityName = entityName;

        if (['Executing Broker', 'Clearing Broker', 'Client'].includes(entityName)) {
            updatedEntityName = 'Company';
        }

        if (entityName === 'Account' && columnField.trim() !== '') {
            fieldMap['Account'] = columnField;
        }

        const fieldVal = entityMap[entityName] || '';
        const fieldName = fieldMap[entityName] || '';

        const aliasData = {
            type: updatedEntityName,
            fieldName,
            alias,
            [fieldVal]: value,
            // @ts-ignore
            exchangeMicCode: entityName === 'Contract' ? exchangeMic : '',
            assetClass: entityName === 'Contract' ? assetClass : ''
        };

        try {
            // @ts-ignore
            await AliasService.createAlias(aliasData);
            handleOpenAliasConfDialog();
            setTimeout(() => {
                handleCloseAliasConfDialog();
            }, 2000);
        } catch (error) {
            // @ts-ignore
            console.error('Error creating alias:', error.response?.data.result || error);
            setAliasError('Error creating alias. Please try again.');
        }
    };

    const handleOpenAliasConfDialog = () => {
        setOpenAliasConfDialog(true);
    };

    const handleCloseAliasConfDialog = () => {
        setOpenAliasConfDialog(false);
        setShowForm(false);
    };

    const rowStyle: InfiniteTablePropRowStyle<UnvalidatedTrade> = ({rowInfo}) => {
        if (rowInfo.isGroupRow) return;

        // @ts-ignore
        const tradeID = rowInfo.data?.tradeID;
        const hasError = errorTradeIds.includes(tradeID);

        if (hasError) {
            const errorDetail = errorDetails.find(err => err.id === tradeID && err.field === 'tradeId');
            if (errorDetail) {
                return undefined;
            }
            return {background: 'tomato'};
        }
        return undefined;
    };


    const getCellContextMenuItems = ({data, column}: { data: Partial<UnvalidatedTrade> | null; column: any }) => {
        if (!data?.tradeID || !errorTradeIds.includes(data.tradeID)) return [];

        const error = errorDetails.find(err => err.id === data.tradeID && err.field === column.field);

        if (!error) return [];

        const items = [{key: 'errorMessage', label: error.message || 'No errors found'}];
        const nullItems = [{key: 'errorMessage', label: error.message.replace("[null]", "[]") || 'No errors found'}];

        if (column.entityName && !error.message.includes("[null]")) {
            fetchAliasValues(
                column.entityName,
                data?.mic || '',
                data?.optionType && data?.strike ? "Option" : "Future",
                column.id
            );

            items.push({
                key: 'alias',
                label: 'Create Alias',
                // @ts-ignore
                onAction: ({hideMenu}: { hideMenu: () => void }) => {
                    setEntityName(column.entityName);
                    setColumnField(column.field);
                    setValue('');
                    // @ts-ignore
                    setAlias(data?.[column.field] || '');
                    setShowForm(true);
                    hideMenu();
                },
            });
            return items;
        } else if (column.entityName && error.message.includes("[null]")) {
            nullItems.push({
                key: 'er',
                label: 'Value must not be blank'
            });
            return nullItems;
        }
    };


    return (
        <>
            <VOCSETLayout dropdownItems={dropdownItems} style={{
                display: "flex",
                flexFlow: "column",
                flex: 1
            }}>
                <>
                    {selectedBlotter === 'trades' ? (
                        <>
                            <ButtonGroup>
                                <CSVLink data={trades} filename="trades.csv">
                                    <Button component="span" disabled={trades.length <= 0} variant="contained" startIcon={<CloudDownload/>}>
                                        Export Trades
                                    </Button>
                                </CSVLink>
                            </ButtonGroup>
                        </>
                    ) : (
                        unvalidatedTrades.length === 0 && imported ? (
                            <>
                                <ButtonGroup style={{gap: '10px'}}>
                                    <Button component="label" variant="contained" startIcon={<CloudUploadIcon/>}>
                                        Upload file
                                        <VisuallyHiddenInput type="file" onChange={handleFile}/>
                                    </Button>
                                </ButtonGroup>
                                <p style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    No valid trades found.
                                </p>
                            </>
                        ) : unvalidatedTrades.length === 0 ? (
                            <>
                                <ButtonGroup style={{gap: '10px'}}>
                                    <Button component="label" variant="contained" startIcon={<CloudUploadIcon/>}>
                                        Upload file
                                        <VisuallyHiddenInput type="file" onChange={handleFile}/>
                                    </Button>
                                    <Button component="label" variant="contained" disabled={true}
                                            startIcon={<CloudDoneIcon/>} color="success">
                                        Send Trades
                                    </Button>
                                </ButtonGroup>
                            </>
                        ) : (
                            <>
                                <ButtonGroup style={{gap: '10px'}}>
                                    <Button component="label" variant="contained" startIcon={<CloudUploadIcon/>}>
                                        Upload file
                                        <VisuallyHiddenInput type="file" onChange={handleFile}/>
                                    </Button>
                                    <Button component="label" variant="contained" onClick={handleSubmitTrades}
                                            startIcon={<CloudDoneIcon/>} color="success">
                                        Send Trades
                                    </Button>
                                </ButtonGroup>
                            </>
                        )
                    )}
                </>
                <Tabs
                    value={selectedBlotter}
                    onChange={(event, newValue) => {
                        setSelectedBlotter(newValue);
                    }}
                    aria-label="Blotter Tabs"
                >
                    <Tab label="Trades" value="trades"/>
                    <Tab label="Import" value="import"/>
                </Tabs>

                {loading ? (
                    <MoonLoader color="#282c34"/>
                ) : (
                    selectedBlotter === 'trades' ? (
                        <>
                            <div className="infinite-theme-mode--light"
                                 style={{display: "flex", flexFlow: "column", flex: 1}}>
                                <DataSource<Trade> data={trades} primaryKey="tradeID">
                                    <InfiniteTable<Trade> columns={tradeColumns}/>
                                </DataSource>
                            </div>
                        </>
                    ) : (
                        <div className="infinite-theme-mode--light"
                             style={{display: "flex", flexFlow: "column", flex: 1}}>
                            <DataSource<UnvalidatedTrade> data={unvalidatedTrades} primaryKey="tradeID"
                                                          cellSelection={cellSelection}
                                                          selectionMode="multi-cell">
                                <InfiniteTable<UnvalidatedTrade>
                                    columns={unvalidatedTradeColumns}
                                    rowStyle={rowStyle}
                                    getCellContextMenuItems={getCellContextMenuItems}
                                />
                            </DataSource>
                        </div>
                    )
                )}
            </VOCSETLayout>

            <Dialog open={openAliasConfDialog} onClose={() => setOpenAliasConfDialog(false)}>
                <DialogContent>
                    <Typography variant="body1">Alias created successfully!</Typography>
                </DialogContent>
            </Dialog>

            <Dialog open={openTradeConfDialog} onClose={handleCloseTradeConfDialog}>
                <DialogTitle>Trade Submission Result</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {successCount} trades were successfully submitted.
                        {errorCount > 0 && ` ${errorCount} trade(s) contained errors.`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseTradeConfDialog} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

                {showForm && (
                    <Dialog open={showForm} onClose={() => setShowForm(false)}>
                        <DialogTitle>Create Alias</DialogTitle>
                        <DialogContent>
                            <Select
                                fullWidth
                                id="value"
                                value={value}
                                onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    setValue(selectedValue);
                                    if (entityName === 'Contract') {
                                        // @ts-ignore
                                        const selectedItem = valueOptions.find(item => item.contractCode === selectedValue);
                                        // @ts-ignore
                                        setAssetClass(selectedItem?.assetClass || '');
                                    } else {
                                        setAssetClass('');
                                    }
                                }}
                                displayEmpty
                                sx={{marginTop: '16px'}}
                                disabled={!(entityName === 'Contract' ? contractOptions : valueOptions).length}
                                MenuProps={{PaperProps: {sx: {maxHeight: 350}}}}
                            >
                                {(entityName === 'Contract' ? contractOptions : valueOptions).map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                )) || (
                                    <MenuItem value="">
                                        {entityName === 'Contract' ? 'No contract values available' : 'Select Value'}
                                    </MenuItem>
                                )}
                            </Select>
                            {entityName === 'Contract' && valueOptions.length === 0 && (
                                <Typography variant="body2" color="error" sx={{marginTop: '16px'}}>
                                    Error: Invalid MIC.
                                </Typography>
                            )}
                            {aliasError && (
                                <Typography variant="body2" color="error" sx={{marginTop: '16px'}}>
                                    {aliasError}
                                </Typography>
                            )}
                        </DialogContent>
                        <DialogActions
                            sx={{
                                justifyContent: (entityName === 'Contract' && !contractOptions.length) ? 'center' : 'flex-end',
                            }}
                        >
                            {!(entityName === 'Contract' && !contractOptions.length) ? (
                                <>
                                    <Button
                                        onClick={handleSubmitAlias}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        onClick={() => setShowForm(false)}
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                </>
                            ) : (
                                <Button
                                    onClick={() => setShowForm(false)}
                                    variant="contained"
                                >
                                    Cancel
                                </Button>
                            )}
                        </DialogActions>
                    </Dialog>
                )}
        </>
    );

};

export default TradeDashboard;

function setError(arg0: string) {
    throw new Error("Function not implemented.");
}

