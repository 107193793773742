import axios from "axios";
import {jwtDecode} from "jwt-decode";

const AUTH_URL = "/api/auth";

class AuthService {
    login(username: string, password: string, totpCode: string) {
        return axios
            .post(AUTH_URL, {
                username,
                password,
                totpCode
            })
            .then(response => {
                if (response.data.result) {
                    if (response.data.result[0].id === "2FRQ") {
                        console.log("2FA Required")
                    } else if (response.data.result[0].id === "AUTH") {
                        localStorage.setItem("token", response.data.result[0].message);
                        const decodedToken = jwtDecode(response.data.result[0].message);
                        console.log(decodedToken);
                        localStorage.setItem("currentUser", decodedToken.sub as string)
                        // @ts-ignore
                        localStorage.setObj("privileges", decodedToken["privileges"])
                    }
                }
                return response.data.result[0];
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    }

    resetPassword(username: string): Promise<string | boolean> {
        return axios
            .post(`${AUTH_URL}/resetPassword`, {username})
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    }

    savePassword(username: string, newPassword: string): Promise<string | boolean> {
        return axios
            .post(`${AUTH_URL}/savePassword`, {username, newPassword})
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    }

    addTOTPToken(username: string): Promise<string | boolean> {
        return axios
            .post(`${AUTH_URL}/totp`, {username})
            .then(response => {
                if (response.data.result === "TOTP exists") {
                    return true;
                }
                return response.data.result[0].message;
            })
            .catch(error => {
                console.error("Error adding TOTP token", error);
                throw error;
            });
    }
}

var authService = new AuthService();
export default authService;
