import React, { Component } from 'react';
import Main from './views/Main';

// Patch the storage object to accept arrays
Storage.prototype.setObj = function(key: string, obj: any) {
    return this.setItem(key, JSON.stringify(obj))
}
Storage.prototype.getObj = function(key: string) {
    // @ts-ignore
    return JSON.parse(this.getItem(key))
}

class App extends Component {
    componentDidMount() {
        // Check if this is the first run of the app, set flag for handling caches below
        const isFirstRun = sessionStorage.getItem('isFirstRun');

        if (!isFirstRun) {
            localStorage.clear();
            sessionStorage.setItem('isFirstRun', 'true');
        }
    }

    render() {
        return (
            <>
                <Main />
            </>
        );
    }
}

export default App;
