import axios from 'axios';
import {handleRequestError} from "../errorHandler";
import authHeader from './auth.header';

const ctype = {'Content-Type': 'application/json'}
const COMPANY_URL = '/api/sdm';

class APIService {

    async createAPIKey(userAPIData: {
        totpCode: string;
        apiKey: string;
        apiSecret: string;
        apiLabel: string;
    }) {
        try {
            const response = await axios.post(`${COMPANY_URL}/user/apiKey`, userAPIData,
                {
                    headers: {...authHeader(), ...ctype}
                });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

    async deleteAPIKey(apiLabel: string, totpCode: string) {
        try {
            const response = await axios.delete(`${COMPANY_URL}/user/apiKey`, {
                params: {
                    apiLabel: apiLabel,
                    totpCode: totpCode
                },
                headers: {...authHeader(), ...ctype}
            });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }
}

export default new APIService();
