import React, {useEffect, useState} from 'react';
import {
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    Tab,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs, Tooltip,
    Typography
} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import {Association, Company, Exchange} from "../../types/APITypes";
import CompanyService from '../../services/company.service';
import Table from '@mui/material/Table';
import {Link, useNavigate} from 'react-router-dom';
import VOCSETLayout, {countryCodeToFlag} from '../../views/VOCSETLayout';
import {dropdownItems} from '../../views/dropdownItems';
import {MoonLoader} from "react-spinners";
import ClearerAssociations from './ClearerAssocations';
import ExecAssociations from './ExecAssociations';
import AssociationService from '../../services/association.service';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import ExchangeService from "../../services/exchange.service";

createTheme();

const CompanyDashboard = () => {
    const [companies, setCompanies] = useState<Company[]>([]);
    const [exchanges, setExchanges] = useState<Exchange[]>([]);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const [selectedRole, setSelectedRole] = useState<string>('My Company');
    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
    const [clrAssociations, setClrAssociations] = useState<Association[]>([]);
    const [execAssociations, setExecAssociations] = useState<Association[]>([]);
    const [openAssociationDialog, setOpenAssociationDialog] = useState(false);
    const [openClearerDialog, setOpenClearerDialog] = useState(false);
    const [openExecDialog, setOpenExecDialog] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [privileges, setPrivileges] = useState<string[]>([]);
    const [action, setAction] = useState<'activate' | 'delete' | 'error' | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const storedPrivileges = localStorage.getObj('privileges');
        if (storedPrivileges) {
            setPrivileges(storedPrivileges);
        }
    }, []);

    const fetchCompanies = async () => {
        setLoading(true);
        try {
            const response = await CompanyService.getCompanies();
            // set the tab to the role of the first company
            const uniqueRoles = Array.from(new Set(response.result.flatMap((company: {
                roles: any[];
            }) => company.roles.map(role => role.name))));
            if (uniqueRoles.length > 0) {
                // @ts-ignore
                setSelectedRole(uniqueRoles[0]);
            }
            setCompanies(response.result);
        } catch (error) {
            console.error('Error fetching companies:', error);
            setError('Error fetching companies. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const fetchExchanges = async () => {
        try {
            const response = await ExchangeService.getExchanges();
            setExchanges(response.result);
        } catch (error) {
            console.error('Error fetching exchanges:', error);
            setError('Error fetching exchanges. Please try again.');
        }
    };

    useEffect(() => {
        fetchCompanies();
        fetchExchanges();
    }, []);

    const companyOwnsExchange = (shortName: string) => {
        return exchanges.some(exchange => exchange.ownerName === shortName);
    };

    const uniqueRoles = Array.from(new Set(companies.flatMap(company => company.roles.map(role => role.name))));
    const displayRoles = uniqueRoles.map(role => role.replace(/_/g, ' '));
    const filteredCompanies = companies.filter(company => company.roles.some(role => role.name === selectedRole));

    const handleClientNameClick = (company: Company) => {
        setSelectedCompany(company);
        setOpenAssociationDialog(true);
    };

    const handleCloseAssociationDialog = async () => {
        setOpenAssociationDialog(false);
        setSelectedCompany(null);

        try {
            setLoading(true);
            const fetchedData = await AssociationService.getAssociations();
            setClrAssociations(fetchedData.filter((asc: {
                associationTypeName: string
            }) => asc.associationTypeName === 'clearsWith'));
            setExecAssociations(fetchedData.filter((asc: {
                associationTypeName: string
            }) => asc.associationTypeName === 'executesWith'));
        } catch (error) {
            console.error('Error fetching associations:', error);
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error fetching associations. Please try again.');
        } finally {
            setLoading(false);
        }
    };


    const handleOpenClearerDialog = () => {
        setOpenAssociationDialog(false);
        setOpenClearerDialog(true);
    };

    const handleOpenExecDialog = () => {
        setOpenAssociationDialog(false);
        setOpenExecDialog(true);
    };

    let dialogContent = '';
    if (action === 'activate') {
        dialogContent = 'Activation complete';
    } else if (action === 'delete') {
        dialogContent = 'Deactivation complete';
    } else if (action === 'error') {
        dialogContent = error;
    }

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDelete = async (shortName: string) => {
        try {
            await CompanyService.deleteCompany(shortName);
            setAction('delete');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error: any) {
            const errorMessage = error?.response?.data?.result?.[0]?.message || 'Error deleting company. Please try again.';
            setError(errorMessage);
            setAction('error');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        }
    };

    const handleActivation = async (company: Company) => {
        try {
            const updatedCompanyData = {
                ...company,
                active: true,
            };

            await CompanyService.updateCompany(updatedCompanyData);

            setAction('activate');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);

        } catch (error: any) {
            console.error('Error activating company:', error);
            const errorMessage = error?.response?.data?.result?.[0]?.message || 'Error activating company. Please try again.';
            setError(errorMessage);
            setAction('error');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        }
    };


    const fetchAssociations = async () => {
        try {
            const fetchedData = await AssociationService.getAssociations();
            setClrAssociations(fetchedData.filter((asc: {
                associationTypeName: string
            }) => asc.associationTypeName === 'clearsWith'));
            setExecAssociations(fetchedData.filter((asc: {
                associationTypeName: string
            }) => asc.associationTypeName === 'executesWith'));
        } catch (error) {
            console.error('Error fetching associations', error);
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error fetching associations. Please try again.');
        }
    };

    useEffect(() => {
        if (privileges.includes('READ_ANY_ASSOCIATION')) {
            const fetchData = async () => {
                await fetchAssociations();
            };

            fetchData().catch(error => console.error('Error fetching associations:', error));
        }
    }, [privileges]);

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                {privileges.includes('CREATE_ANY_COMPANY') && (
                    <ButtonGroup>
                        <Button aria-label="Company Buttons" variant="contained" component={Link} to="/CreateCompany"
                                startIcon={<AddIcon/>}>New Company</Button>
                    </ButtonGroup>
                )}
                <Tabs value={selectedRole} onChange={(event, newValue) => setSelectedRole(newValue)}
                      aria-label="Roles Tabs">
                    {displayRoles.map((role, index) => (
                        <Tab key={uniqueRoles[index]} label={role} value={uniqueRoles[index]}/>
                    ))}
                </Tabs>
                {loading ? (
                    <MoonLoader color="#282c34"/>
                ) : (
                    companies.length === 0 ? (
                        <p>No companies found.</p>
                    ) : (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>City</TableCell>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Country</TableCell>
                                        {privileges.includes('WRITE_ANY_COMPANY') && (
                                            <TableCell>Actions</TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredCompanies.map((company) => {
                                        return (
                                            <TableRow key={company.id}
                                                      style={{backgroundColor: company.active ? '' : '#f5f5f5'}}>
                                                <TableCell>{company.shortName}</TableCell>
                                                <TableCell>
                                                    {selectedRole === 'CLIENT' ? (
                                                        <Button onClick={() => handleClientNameClick(company)}
                                                                variant="text" style={{textTransform: 'none'}}>
                                                            {company.name}
                                                        </Button>
                                                    ) : (
                                                        <Typography>{company.name}</Typography>
                                                    )}
                                                </TableCell>
                                                <TableCell>{company.city}</TableCell>
                                                <TableCell>{company.code}</TableCell>
                                                <TableCell>
                                                    <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                                                        <Tooltip title={company.countryCode} arrow>
                                                            <Typography variant="h6" color="inherit" noWrap>
                                                                {countryCodeToFlag(company.countryCode)}
                                                            </Typography>
                                                        </Tooltip>
                                                    </div>
                                                </TableCell>
                                                {privileges.includes('WRITE_ANY_COMPANY') && (
                                                    <TableCell>
                                                        <ButtonGroup className="dash-button-group">
                                                            <Link to={`/EditCompany`} state={company}>
                                                                <Button className="dash-button"
                                                                        startIcon={<EditIcon/>}/>
                                                            </Link>
                                                            {!companyOwnsExchange(company.shortName) && (
                                                                <>
                                                                    {company.active ? (
                                                                        <Button
                                                                            className="dash-button"
                                                                            startIcon={<DeleteIcon/>}
                                                                            onClick={() => handleDelete(company.shortName)}
                                                                        />
                                                                    ) : (
                                                                        <Button
                                                                            className="dash-button"
                                                                            startIcon={<DoneIcon/>}
                                                                            onClick={() => handleActivation(company)}
                                                                        />
                                                                    )}
                                                                </>
                                                            )}
                                                        </ButtonGroup>
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                )}

                <Dialog open={openAssociationDialog} onClose={handleCloseAssociationDialog}>
                    <DialogContent>
                        <Typography component="h1" variant="h5" sx={{alignSelf: 'flex-start'}}>
                            Manage Associations
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleOpenClearerDialog} variant="contained" color="primary">
                            Clearing Brokers
                        </Button>
                        <Button onClick={handleOpenExecDialog} variant="contained" color="primary">
                            Executing Brokers
                        </Button>
                        <Button onClick={handleCloseAssociationDialog} variant="contained" color="secondary"
                                sx={{bgcolor: "red", '&:hover': {bgcolor: "red"}}}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openClearerDialog} onClose={() => setOpenClearerDialog(false)}>
                    {openClearerDialog && selectedCompany && clrAssociations.length > 0 &&
                        companies.length > 0 && (
                            <ClearerAssociations
                                selectedCompany={selectedCompany}
                                associations={clrAssociations}
                                companies={companies}
                                onClose={() => setOpenClearerDialog(false)}
                                onAssociationUpdate={fetchAssociations}
                            />
                        )}
                </Dialog>

                <Dialog open={openExecDialog} onClose={() => setOpenExecDialog(false)}>
                    {openExecDialog && selectedCompany && execAssociations.length > 0 &&
                        companies.length > 0 && (
                            <ExecAssociations
                                selectedCompany={selectedCompany}
                                associations={execAssociations}
                                companies={companies}
                                onClose={() => setOpenExecDialog(false)}
                                onAssociationUpdate={fetchAssociations}
                            />
                        )}
                </Dialog>

                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogContent>
                        <Typography variant="body1">{dialogContent}</Typography>
                    </DialogContent>
                </Dialog>

            </VOCSETLayout>
        </div>
    );
};

export default CompanyDashboard;
