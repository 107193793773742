import axios from 'axios';
import { buildWebStorage, setupCache } from 'axios-cache-interceptor/dev';

const instance = axios.create();

const axiosCache = setupCache(
    instance,
    {
        ttl: 1000 * 10,
        interpretHeader: false,
        storage: buildWebStorage(localStorage, 'axios-cache-'),
        debug: console.log,
    }
);

export default axiosCache;