import React, {useEffect, useState} from 'react';
import {
    Box, Button, CssBaseline, TextField, Typography, Dialog,
    DialogContent, Select, MenuItem
} from '@mui/material';
import AliasService from '../../services/alias.service';
import {Link, useNavigate} from "react-router-dom";
import VOCSETLayout from '../../views/VOCSETLayout';
import {Grid} from '@mui/material';
import {dropdownItems} from "../../views/dropdownItems";

interface Contract {
    assetClass: string;
    contractCode: string;
    exchangeMic: string;
    micAlias: string;
}

function CreateAlias() {
    const [entityName, setEntityName] = useState('');
    const [alias, setAlias] = useState('');
    const [value, setValue] = useState('');
    const [valueOptions, setValueOptions] = useState([]);
    const [assetClass, setAssetClass] = useState('');
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [exchangeMicCode, setExchangeMicCode] = useState('');
    const navigate = useNavigate();

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/AliasDashboard');
    };

    useEffect(() => {
        let updatedEntityName = entityName;
        if (updatedEntityName) {
            AliasService.findAliasValues(updatedEntityName).then((fetchedData) => {
                setValueOptions(fetchedData);
            }).catch((error) => {
                console.error('Error fetching alias values:', error);
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching alias values. Please try again.');
            });
        }
    }, [entityName]);


    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        try {
            const entityMap: Record<string, string> = {
                'Exchange': 'micCode',
                'Executing Broker': 'companyShortName',
                'Clearing Broker': 'companyShortName',
                'Client': 'companyShortName',
            };

            const fieldMap: Record<string, string> = {
                'Exchange': 'mic',
                'Executing Broker': 'executingBroker',
                'Clearing Broker': 'clearingBroker',
                'Client': 'client',
            };

            let updatedEntityName = entityName;

            if (['Executing Broker', 'Clearing Broker', 'Client'].includes(entityName)) {
                updatedEntityName = 'Company';
            }

            const fieldVal = entityMap[entityName] || '';
            const fieldName = fieldMap[entityName] || '';
            const aliasData = {
                type: updatedEntityName,
                fieldName,
                alias,
                [fieldVal]: value,
                exchangeMicCode,
                assetClass: 'Future'
            };

            await AliasService.createAlias(aliasData);
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            console.error('Error creating alias:', error);
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error creating alias. Please try again.');
        }
    };

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%'
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{alignSelf: 'flex-start'}}>
                        Create Alias
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 3, width: '100%'}}>
                        <Grid container spacing={2} sx={{paddingBottom: 4}}>
                            <Grid item xs={4}>
                                <Select
                                    fullWidth
                                    id="entityName"
                                    value={entityName}
                                    onChange={(e) => setEntityName(e.target.value)}
                                    renderValue={(selected) => (selected === '' ? 'Select Entity' : selected)}
                                    displayEmpty
                                    sx={{marginTop: '16px'}}
                                >
                                    <MenuItem value="Exchange">Exchange</MenuItem>
                                    <MenuItem value="Executing Broker">Executing Broker</MenuItem>
                                    <MenuItem value="Clearing Broker">Clearing Broker</MenuItem>
                                    <MenuItem value="Client">Client</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    fullWidth
                                    id="value"
                                    value={value}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        setValue(selectedValue);
                                        setAssetClass('');
                                    }}
                                    displayEmpty
                                    sx={{marginTop: '16px'}}
                                    disabled={!valueOptions.length}
                                    MenuProps={{
                                        PaperProps: {sx: {maxHeight: 350}},
                                    }}
                                >
                                    {valueOptions.length > 0 ? (
                                        valueOptions.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value="">
                                            {valueOptions.length === 0 ? 'No values available' : 'Select Value'}
                                        </MenuItem>
                                    )}
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="alias"
                                    label="Alias"
                                    value={alias}
                                    onChange={(e) => setAlias(e.target.value)}
                                    sx={{marginTop: '16px'}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{width: 'auto', mb: 2}}
                                >
                                    Save
                                </Button>
                                <Button
                                    component={Link}
                                    to="/AliasDashboard"
                                    variant="contained"
                                    color="primary"
                                    sx={{width: 'auto', mb: 2, marginLeft: 3}}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    {error && (
                        <Typography variant="body2" color="error" sx={{alignSelf: 'flex-start'}}>
                            {error}
                        </Typography>
                    )}
                </Box>
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">Alias created successfully!</Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default CreateAlias;
