import {UnvalidatedTrade} from "../../../types/APITypes";

export const vocsetColumns = {
    tradeID: {field: 'tradeID', header: 'TradeID'},
    tradeDate: {field: 'tradeDate', header: 'Trade Date'},
    side: {field: 'side', header: 'Side'},
    quantity: {field: 'quantity', header: 'Qty', type: 'number'},
    price: {field: 'price', header: 'Price', type: 'number'},
    instrumentCode: {field: 'instrumentCode', header: 'Instrument', entityName: 'Contract'},
    maturity: {field: 'maturity', header: 'Maturity'},
    mic: {field: 'mic', header: 'Exchange', entityName: 'Exchange'},
    client: {field: 'client', header: 'Client', entityName: 'Client'},
    executingBroker: {field: 'executingBroker', header: 'Broker', entityName: 'Executing Broker'},
    executingAccount: {field: 'executingAccount', header: 'Ex Account', entityName: 'Account'},
    productDescription: {field: 'productDescription', header: 'Description'},
    optionType: {field: 'optionType', header: 'Put/Call'},
    strike: {field: 'strike', header: 'Strike', type: 'number'},
    clearingAccount: {field: 'clearingAccount', header: 'Clr Account', entityName: 'Account'},
    clearingBroker: {field: 'clearingBroker', header: 'Clearer', entityName: 'Clearing Broker'},
    executionTime: {field: 'executionTime', header: 'Exec Time'},
    giveupTime: {field: 'giveupTime', header: 'Submission Time'},
};

export async function formatVocsetTrade(tradeData: { [key: string]: any }): Promise<Partial<UnvalidatedTrade>> {
    const vocsetTradeData: Partial<UnvalidatedTrade> = {...tradeData};
    const mappedData: Partial<UnvalidatedTrade> = {};
    const profile = JSON.parse(localStorage.getItem('userProfile') || '{}');
    const userCompany = profile.ownerShortName;

    if (vocsetTradeData['executionTime']) {
        const originalExecTime = new Date(vocsetTradeData['executionTime']);
        vocsetTradeData['executionTime'] = originalExecTime.toISOString();
    }

    if (vocsetTradeData['giveupTime']) {
        const originalGiveupTime = new Date(vocsetTradeData['giveupTime']);
        vocsetTradeData['giveupTime'] = originalGiveupTime.toISOString();
    }

    if (userCompany) {
        vocsetTradeData['executingBroker'] = userCompany;
    }



    for (const vocsetField in vocsetColumns) {
        // @ts-ignore
        const {field} = vocsetColumns[vocsetField];
        // @ts-ignore
        if (vocsetTradeData[vocsetField] !== undefined) {
            // @ts-ignore
            mappedData[field] = vocsetTradeData[vocsetField];
        }
    }

    console.log('vocset mapped ', mappedData)

    return mappedData;
}
