import axios from 'axios';
import { handleRequestError } from '../errorHandler';
import authHeader from "./auth.header";
import axiosCache from '../components/cache/axiosCache'

const SDM_SERVICE_URL = '/api/sdm';
const ctype = { 'Content-Type' : 'application/json'}

class ExchangeService {
    async getExchanges() {
        try {
            const response = await axiosCache.get(`${SDM_SERVICE_URL}/exchange`,
            { headers: {...authHeader(), ...ctype}, id: 'exchange',
                    params: {isActive: false}
            });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

    async updateExchange(exchangeData: {
        name: string;
        mic: string;
        countryCode: string;
        active: boolean;
        ownerName: string;
    }) {
        try {
            const response = await axios.post(`${SDM_SERVICE_URL}/updateExchange`, exchangeData,
                { headers: {...authHeader(), ...ctype}
            });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

    async createExchange(exchangeData: {
        name: string;
        mic: string;
        countryCode: string;
        active: boolean;
        ownerName: string;
    }) {
        try {
            const response = await axios.post(`${SDM_SERVICE_URL}/exchange`, exchangeData,
                { headers: {...authHeader(), ...ctype}
            });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }


    async deleteExchange(mic: string) {
        try {
            const response = await axios.delete(`${SDM_SERVICE_URL}/exchange`,
                { headers: {...authHeader(), ...ctype},
                        params: { mic: mic }
            });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }
}

export default new ExchangeService();
