import React, {useState, useEffect} from 'react';
import {
    Box,
    Button,
    CssBaseline,
    TextField,
    Typography,
    Dialog,
    DialogContent,
    Select, MenuItem
} from '@mui/material';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {dropdownItems} from '../../views/dropdownItems';
import VOCSETLayout from "../../views/VOCSETLayout";
import {Grid} from '@mui/material';
import ExchangeService from "../../services/exchange.service";
import CompanyService from "../../services/company.service";


interface EditExchangeProps {
    onSuccess: () => void;
}

const EditExchange: React.FC<EditExchangeProps> = ({onSuccess}) => {
    const [name, setName] = useState('');
    const [mic, setMic] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [active, setActive] = useState(true);
    const [companies, setCompanies] = useState<any[]>([]);
    const [companyName, setCompanyName] = useState('');
    const [companyShortName, setCompanyShortName] = useState('');
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const location = useLocation();
    const [action, setAction] = useState<'save' | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchCompanies() {
            try {
                const response = await CompanyService.getCompanies();
                const fetchedCompanies = response.result;
                const operatingCompanies = fetchedCompanies.filter((company: {
                        roles: any[];
                        active: boolean;
                    }) =>
                        company.roles.some(role => role.name === 'EXCHANGE_OPERATOR') && company.active
                );
                if (operatingCompanies.length > 0) {
                    setCompanies(operatingCompanies);
                } else {
                    setError('No companies available.');
                }
            } catch (error) {
                console.error('Error fetching companies:', error);
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching companies. Please try again.');
            }
        }

        fetchCompanies();
    }, []);


    const exchange = location.state || {}

    useEffect(() => {
        setName(exchange.name || '');
        setMic(exchange.mic || '');
        setCountryCode(exchange.countryCode || '');
        setCompanyShortName(exchange.ownerName || '');
        if (companies.length > 0) {
            const selectedCompany = companies.find(company => company.shortName === companyShortName);
            setCompanyName(selectedCompany ? selectedCompany.name : '');
        }
        setActive(exchange.hasOwnProperty('active') ? exchange.active : true);
    }, [exchange]);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/ExchangeDashboard')
    };


    const handleSave = async (actionType?: 'activate' | 'save') => {
        try {
            const updatedExchangeData = {
                name,
                mic,
                countryCode,
                active: actionType === 'activate' ? true : active,
                ownerName: companyShortName
            };
            await ExchangeService.updateExchange(updatedExchangeData);
            onSuccess();
            setAction('save');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            console.error('Error updating exchange:', error);
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error updating exchange. Please try again.');
        }
    };

    let dialogContent = '';
    if (action === 'save') {
        dialogContent = 'Update complete';
    }

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{alignSelf: 'flex-start'}}>
                        Edit Exchange
                    </Typography>
                    <Box component="form" noValidate sx={{mt: 3}}>
                        <Grid container spacing={2} sx={{paddingBottom: 4}}>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="mic"
                                    label="MIC"
                                    value={mic}
                                    disabled
                                    onChange={(e) => setMic(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="countryCode"
                                    label="Country Code"
                                    value={countryCode}
                                    onChange={(e) => setCountryCode(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    fullWidth
                                    id="company"
                                    label="Company"
                                    value={companies.find(company => company.shortName === companyShortName)?.name || ''}
                                    onChange={(e) => {
                                        const selectedCompanyName = e.target.value;
                                        const selectedCompany = companies.find(company => company.name === selectedCompanyName);
                                        if (selectedCompany) {
                                            setCompanyName(selectedCompanyName); // Update companyName directly
                                            setCompanyShortName(selectedCompany.shortName)
                                        }
                                    }}
                                    sx={{marginTop: '16px'}}
                                >
                                    {companies.map((company: any) => (
                                        <MenuItem key={company.id} value={company.name}>{company.name}</MenuItem>
                                    ))}
                                </Select>

                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                onClick={() => handleSave('save')}
                                variant="contained"
                                sx={{width: 'auto', mb: 2}}
                            >
                                Save
                            </Button>
                            <Button
                                component={Link}
                                to="/ExchangeDashboard"
                                variant="contained"
                                color="primary"
                                sx={{width: 'auto', mb: 2, marginLeft: 3}}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Box>
                    {error && (
                        <Typography variant="body2" color="error" sx={{ alignSelf: 'flex-start' }}>
                            {error}
                        </Typography>
                    )}
                </Box>
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">{dialogContent}</Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default EditExchange;
