import React, {useState, useEffect} from 'react';
import {
    Box,
    Button,
    CssBaseline,
    TextField,
    Typography,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    DialogActions
} from '@mui/material';
import { parseISO, format, addDays, isAfter, differenceInDays } from 'date-fns';
import {MoonLoader} from 'react-spinners';
import UserService from '../../services/user.service';
import APIService from '../../services/api.service';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {dropdownItems} from '../../views/dropdownItems';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import VOCSETLayout from '../../views/VOCSETLayout';

interface APIDashboardProps {
    onSuccess: () => void;
}

const APIDashboard: React.FC<APIDashboardProps> = ({onSuccess}) => {
    const [username, setUsername] = useState('');
    const [apiKey, setAPIKey] = useState('');
    const [apiSecret, setAPISecret] = useState('');
    const [apiLabel, setAPILabel] = useState('');
    const [daysTilExpiry, setDaysTilExpiry] = useState('');
    const [user, setUser] = useState<any>(null);
    const [userProfileFetched, setUserProfileFetched] = useState(false);
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [apiKeyExpired, setAPIKeyExpired] = useState(false);
    const [totpDialogOpen, setTotpDialogOpen] = useState(false);
    const [totpCode, setTotpCode] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const getUserData = async () => {
            try {
                const userData = await UserService.getSingleUser(localStorage.getItem('currentUser'));
                setUser(userData);
                setUsername(userData.username || '');
                setAPIKey(userData.apiKey || '');
                setAPISecret(userData.apiSecret || '');
                setAPILabel(userData.apiLabel || '');
                if (userData.apiKeyCreationDate) {
                    const creationDate = parseISO(userData.apiKeyCreationDate);
                    const expiryDate = addDays(creationDate, 60);
                    const today = new Date();
                    if (isAfter(today, expiryDate)) {
                        setAPIKeyExpired(true);
                        setDaysTilExpiry(`Your current API Key has expired. Please renew.`);
                    } else {
                        const daysUntilExpiry = differenceInDays(expiryDate, today);
                        setDaysTilExpiry(`${daysUntilExpiry} days`);
                    }
                } 
                setLoading(false);
                setUserProfileFetched(true);
            } catch (error) {
                console.error('Error fetching user data:', error);
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching user data. Please try again.');
            }
        };

        getUserData();
    }, [location.state]);

    const handleAPIKeyDeleteRequest = async () => {
        try {
            await APIService.deleteAPIKey(apiLabel, totpCode);
            onSuccess();
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            console.error('Error deleting api key:', error);
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error deleting API key. Please try again.');
        }
    };

    const handleDelete = async () => {
        setTotpDialogOpen(true);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            console.log('Copied to clipboard:', text);
        }).catch(err => {
            console.error('Could not copy text:', err);
        });
    };

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <CssBaseline/>
                <Box sx={{marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Typography component="h1" variant="h5" sx={{alignSelf: 'flex-start'}}>
                        API Management
                    </Typography>
                    {loading ? (
                        <MoonLoader color="#282c34"/>
                    ) : (
                        <Box component="form" noValidate sx={{mt: 3}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {!apiKey ? (
                                        <Link to="/CreateAPIKey" state={user}
                                              style={{textDecoration: 'none', color: 'inherit'}}>
                                            <Button variant="contained">Create API Key</Button>
                                        </Link>
                                    ) : (
                                        <>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" sx={{fontWeight: 'bold'}}>API
                                                    Label:</Typography>
                                                <Typography gutterBottom>{apiLabel}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" sx={{fontWeight: 'bold'}}>API
                                                    Key:</Typography>
                                                <Typography gutterBottom>{apiKey}
                                                    <IconButton onClick={() => copyToClipboard(apiKey)}>
                                                        <ContentCopyIcon/>
                                                    </IconButton>Copy</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" sx={{fontWeight: 'bold'}}>** Days until expiry:</Typography>
                                                <Typography gutterBottom>{daysTilExpiry}</Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                                                <Button
                                                    onClick={() => {
                                                        handleDelete()
                                                    }}
                                                    variant="contained"
                                                    color="error"
                                                    sx={{
                                                        width: 'auto',
                                                        mb: 2,
                                                        marginRight: 1,
                                                    }}
                                                >Delete Key </Button>
                                                {apiKeyExpired && (
                                                    <Link to="/CreateAPIKey" state={user}
                                                          style={{textDecoration: 'none', color: 'inherit'}}>
                                                        <Button variant="contained" startIcon={<AutorenewIcon/>}
                                                                sx={{ height: '100%', mb: 2 }}
                                                        >Renew Key</Button>
                                                    </Link>
                                                )}
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            {error && (
                                <Typography variant="body2" color="error">
                                    {error}
                                </Typography>
                            )}
                        </Box>
                    )}
                </Box>
            </VOCSETLayout>
            
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">{`API Key ${apiLabel} has been deleted`}</Typography>
                </DialogContent>
            </Dialog>

            <Dialog open={totpDialogOpen} onClose={() => setTotpDialogOpen(false)}>
                <DialogContent>
                    <Typography variant="h6">OTP Code Authentication</Typography>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="totpCode"
                        label="Verify OTP Code"
                        value={totpCode}
                        onChange={(e) => setTotpCode(e.target.value)}
                    />
                    <div>Enter the 6-digit code generated by the Authenticator App.</div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAPIKeyDeleteRequest} variant="contained">
                        Submit
                    </Button>
                    <Button onClick={handleCloseDialog} variant="contained">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default APIDashboard;
