import React, {useState, useEffect} from 'react';
import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Tab, ButtonGroup
} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import ProductService from '../../services/product.service';
import Table from '@mui/material/Table';
import {Link, useLocation} from 'react-router-dom';
import Button from "@mui/material/Button";
import VOCSETLayout from '../../views/VOCSETLayout';
import {dropdownItems} from '../../views/dropdownItems';
import {MoonLoader} from "react-spinners";
import {Product} from "../../types/APITypes";
import EditIcon from "@mui/icons-material/Edit";

createTheme();

const ProductDashboard = () => {
    const [products, setProducts] = useState<Product[]>([]);
    const [error, setError] = useState<string>('');
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(true);
    const [selectedMic, setSelectedMic] = useState<string>('');
    const [privileges, setPrivileges] = useState<string[]>([]);
    const location = useLocation();

    const resize = () => {
        setOpen(window.innerWidth >= 760);
    };

    useEffect(() => {
        const storedPrivileges = localStorage.getObj('privileges');
        if (storedPrivileges) {
            setPrivileges(storedPrivileges);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('resize', resize);
        resize();
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await ProductService.getProducts();
                setLoading(false);
                const fetchedProducts = response.result;
                if (fetchedProducts && fetchedProducts.length > 0) {
                    setProducts(fetchedProducts);
                    const passedMic = location.state?.selectedMic;
                    const micToSet = passedMic && fetchedProducts.some((product: {
                        mic: any;
                    }) => product.mic === passedMic) ? passedMic : fetchedProducts[0].mic;
                    setSelectedMic(micToSet);
                } else {
                    setError('No products available.');
                }
            } catch (error) {
                // @ts-ignore
                console.error('Error fetching products:', error.response ? error.response.data : error);
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching products. Please try again.');
            }
        };

        fetchProducts();
    }, [location.state]);

    const uniqueMics = Array.from(new Set(products.map(product => product.mic)));

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <Tabs
                    value={selectedMic}
                    onChange={(event, newValue) => setSelectedMic(newValue)}
                    aria-label="Mic Tabs"
                >
                    {uniqueMics.map((mic, index) => (
                        <Tab key={index} label={mic} value={mic}/>
                    ))}
                </Tabs>
                {loading ? (
                    <MoonLoader color="#282c34"/>
                ) : (
                    products.length === 0 ? (
                        <p>No products found.</p>
                    ) : (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Contract Code</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Currency</TableCell>
                                        <TableCell>Maturity Rule</TableCell>
                                        {privileges.includes('WRITE_ANY_PRODUCT') && (
                                            <TableCell>Actions</TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {products.filter(product => product.mic === selectedMic).map(product => (
                                        <TableRow key={product.id}>
                                            <TableCell>{product.contractCode}</TableCell>
                                            <TableCell>{product.description}</TableCell>
                                            <TableCell>{product.currency}</TableCell>
                                            <TableCell>{product.maturityRule}</TableCell>
                                            {privileges.includes('WRITE_ANY_PRODUCT') && (
                                                <TableCell>
                                                    <ButtonGroup className="dash-button-group">
                                                        <Link to={`/EditProduct`} state={product} className="clt-row"
                                                              style={{textDecoration: 'none', color: 'inherit'}}>
                                                            <Button className="dash-button" startIcon={<EditIcon/>}/>
                                                        </Link>
                                                    </ButtonGroup>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                )}
            </VOCSETLayout>
        </div>
    );
}

export default ProductDashboard;
