import React, {useEffect, useState} from 'react';
import {
    Box, Button, CssBaseline, TextField, Typography, Dialog,
    DialogContent, Select, MenuItem
} from '@mui/material';
import CompanyService from '../../services/company.service';
import RoleService from '../../services/role.service';
import {Link, useNavigate} from "react-router-dom";
import {dropdownItems} from "../../views/dropdownItems";
import VOCSETLayout from '../../views/VOCSETLayout';
import {Grid} from '@mui/material';


function CreateCompany() {
    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [region, setRegion] = useState('');
    const [code, setCode] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [roleList, setRoleList] = useState<{
        id: string;
        name: string;
    }[]>([]);
    const [roles, setRoles] = useState('');
    const [active, setActive] = useState(true);
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/CompanyDashboard')
    };

    useEffect(() => {
        async function fetchRoles() {
            try {
                const response = await RoleService.getRolesByType('company');
                const fetchedRoles = response.result;
                if (fetchedRoles && fetchedRoles.length > 0) {
                    setRoleList(fetchedRoles);
                } else {
                    setError('No roles available.');
                }
            } catch (error) {
                console.error('Error fetching roles:', error);
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching roles. Please try again.');
            }
        }

        fetchRoles();
    }, []);

    const handleSubmit = async (event: {
        preventDefault: () => void;
    }) => {
        event.preventDefault();

        try {

            const selectedRoleObject = roleList.find((role) => role.name === roles);

            if (!selectedRoleObject) {
                throw new Error('Selected role not found.');
            }


            const companyData = {
                name,
                shortName,
                address1,
                address2,
                city,
                region,
                code,
                countryCode,
                roles: [{id: selectedRoleObject.id, name: selectedRoleObject.name}],
                active
            };

            await CompanyService.createCompany(companyData);
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            console.error('Error creating company:', error);
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error creating company. Please try again.');
        }
    };

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{alignSelf: 'flex-start'}}>
                        Create Company
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 3}}>
                        <Grid container spacing={2} sx={{paddingBottom: 4}}>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="shortName"
                                    label="Short Name"
                                    value={shortName}
                                    onChange={(e) => setShortName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="address1"
                                    label="Address Line 1"
                                    value={address1}
                                    onChange={(e) => setAddress1(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="address2"
                                    label="Address Line 2"
                                    value={address2}
                                    onChange={(e) => setAddress2(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="city"
                                    label="City"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="region"
                                    label="Region"
                                    value={region}
                                    onChange={(e) => setRegion(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="code"
                                    label="Code"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="countryCode"
                                    label="Country Code"
                                    value={countryCode}
                                    onChange={(e) => setCountryCode(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    fullWidth
                                    id="roles"
                                    label="Roles"
                                    value={roles}
                                    renderValue={(selected) => (selected === '' ? 'Select Role' : selected)}
                                    onChange={(e) => setRoles(e.target.value)}
                                    sx={{marginTop: '16px'}}
                                >
                                    <MenuItem disabled value="">
                                        {roles ? '' : 'Select Role'} {/* Render placeholder text */}
                                    </MenuItem>
                                    {roleList && roleList.map((role: any) => (
                                        <MenuItem key={role.id} value={role.name}>{role.name}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{width: 'auto', mb: 2}}
                            >
                                Save
                            </Button>
                            <Button
                                component={Link}
                                to="/CompanyDashboard"
                                variant="contained"
                                color="primary"
                                sx={{width: 'auto', mb: 2, marginLeft: 3}}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Box>
                    {error && (
                        <Typography variant="body2" color="error" sx={{ alignSelf: 'flex-start' }}>
                            {error}
                        </Typography>
                    )}
                </Box>
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">Company created successfully!</Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default CreateCompany;
