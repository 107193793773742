import axios from 'axios';
import {handleRequestError} from "../errorHandler";
import authHeader from './auth.header';
import axiosCache from '../components/cache/axiosCache'

const ctype = {'Content-Type': 'application/json'}
const COMPANY_URL = '/api/sdm';

class UserService {
    async getUsers() {
        try {
            const response = await axiosCache.get(`${COMPANY_URL}/user`,
                {
                    headers: {...authHeader(), ...ctype}, id: 'user-dash',
                    params: {isActive: false}

                });
            return response.data;
        } catch (error) {
            console.log(error)
            handleRequestError(error);
        }
    }

    async getSingleUser(username: any) {
        try {
            const response = await axiosCache.get(`${COMPANY_URL}/user`,
                {
                    headers: {...authHeader(), ...ctype},
                    params: {username: username}, id: 'user'
                });
            localStorage.setItem('userProfile', JSON.stringify(response.data.result));
            return response.data.result;
        } catch (error) {
            console.log(error)
            handleRequestError(error);
        }
    }

    async updateUser(userData: {
        username: string;
        firstName: string;
        lastName: string;
        ownerShortName: string;
        roles: {
            id: string;
            name: string;
        }[];
        active: boolean;
    }) {
        try {
            const response = await axios.post(`${COMPANY_URL}/updateUser`, userData,
                {
                    headers: {...authHeader(), ...ctype}
                });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

    async createUser(userData: {
        username: string;
        firstName: string;
        lastName: string;
        roles: {
            id: string;
            name: string;
        }[];
        active: boolean;
        ownerShortName: string;
    }) {
        try {
            const response = await axios.post(`${COMPANY_URL}/user`, userData,
                {
                    headers: {...authHeader(), ...ctype},
                });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }


    async deleteUser(username: string) {
        try {
            const response = await axios.delete(`${COMPANY_URL}/user`, {
                params: {
                    username: username
                },
                headers: {...authHeader(), ...ctype}
            });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }
}

export default new UserService();
