import React, {useState, useEffect} from 'react';
import {
    Box,
    Button,
    CssBaseline,
    TextField,
    Typography,
    Dialog,
    DialogContent} from '@mui/material';
import UserService from '../../services/user.service';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {dropdownItems} from '../../views/dropdownItems';
import VOCSETLayout from "../../views/VOCSETLayout";
import {Grid} from '@mui/material';


interface EditUserProps {
    onSuccess: () => void;
}

const EditUser: React.FC<EditUserProps> = ({onSuccess}) => {
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [ownerShortName, setOwnerShortName] = useState('');
    const [ownerName, setOwnerName] = useState('');
    const [roles, setRoles] = useState('');
    const [active, setActive] = useState(true);
    const [user, setUser] = useState<any>(null);
    const [userProfileFetched, setUserProfileFetched] = useState(false);
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const getUserData = async () => {
            try {
                const userData = await UserService.getSingleUser(localStorage.getItem('currentUser'));
                setUser(userData);
                setUserProfileFetched(true);
            } catch (error) {
                console.error('Error fetching user data:', error);
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching user data. Please try again.');
            }
        };

        getUserData();
    }, [location.state]);


    useEffect(() => {
        setUsername(user?.username || '');
        setFirstName(user?.firstName || '');
        setLastName(user?.lastName || '');
        setRoles(user?.roles[0].id || '');
        setActive(user?.active || true);
        setOwnerShortName(user?.ownerShortName || '');
        setOwnerName(user?.ownerName || '');
    }, [user]);


    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/UserDashboard')
    };

    const handleSave = async () => {
        try {
            const updatedUserData = {
                username,
                firstName,
                lastName,
                ownerShortName,
                roles: [{id: user?.roles[0].id, name: user?.roles[0].name}],
                active
            };

            await UserService.updateUser(updatedUserData);
            onSuccess();
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);

        } catch (error) {
            // @ts-ignore
            const status = error.response?.status;
            // @ts-ignore
            const result = error.response?.data.result;
            if (status === 400 && result?.[0]?.id === "CONF") {
                setError('Users must have an assigned company in order to update their profile.');
            } else {
                console.error('Error updating user:', error);
                setError('Error updating user. Please try again.');
            }
        }
    };


    let dialogContent = 'Update complete';


    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{alignSelf: 'flex-start'}}>
                        Edit Profile
                    </Typography>
                    <Box component="form" noValidate sx={{mt: 3}}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="username"
                                    label="Username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="roles"
                                    label="Roles"
                                    value={user?.roles[0].name || ''}
                                    disabled={userProfileFetched}
                                    onChange={(e) => setRoles(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="company"
                                    label="Company"
                                    value={ownerName}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                onClick={handleSave}
                                variant="contained"
                                sx={{width: 'auto', mb: 2}}
                            >
                                Save
                            </Button>
                            <Button
                                component={Link}
                                to="/TradeDashboard"
                                variant="contained"
                                color="primary"
                                sx={{width: 'auto', mb: 2, marginLeft: 3}}
                            >
                                Cancel
                            </Button>
                        </Grid>

                    </Box>
                    {error && (
                        <Typography variant="body2" color="error" sx={{ alignSelf: 'flex-start' }}>
                            {error}
                        </Typography>
                    )}
                </Box>
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">{dialogContent}</Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default EditUser;
