import {UnvalidatedTrade} from "../../../types/APITypes";
import AccountService from '../../../services/account.service';

export const iceColumns: { [key: string]: { vocsetField: string, header?: string, entityName?: string } } = {
    'Leg ID': {vocsetField: 'tradeID', header: 'TradeID'},
    'Trade Date': {vocsetField: 'tradeDate', header: 'Trade Date'},
    'B/S': {vocsetField: 'side', header: 'Side'},
    'Quantity': {vocsetField: 'quantity', header: 'Qty', entityName: 'number'},
    'Price': {vocsetField: 'price', header: 'Price', entityName: 'number'},
    'Product': {vocsetField: 'instrumentCode', header: 'Instrument', entityName: 'Contract'},
    'Contract': {vocsetField: 'maturity', header: 'Maturity'},
    'Hub': {vocsetField: 'mic', header: 'Exchange', entityName: 'Exchange'},
    'client': {vocsetField: 'client', header: 'Client', entityName: 'Client'},
    'Broker Name': {vocsetField: 'executingBroker', header: 'Broker', entityName: 'Executing Broker'},
    'executingAccount': {vocsetField: 'executingAccount', header: 'Ex Account', entityName: 'Account'},
    'Description': {vocsetField: 'productDescription', header: 'Description'},
    'Option': {vocsetField: 'optionType', header: 'Put/Call'},
    'Strike Price': {vocsetField: 'strike', header: 'Strike', entityName: 'number'},
    'Clearing Acct': {vocsetField: 'clearingAccount', header: 'Clr Account', entityName: 'Account'},
    'Clearing Firm': {vocsetField: 'clearingBroker', header: 'Clearer', entityName: 'Clearing Broker'},
    'Transaction Details': {vocsetField: 'comment', header: 'Comment'},
    'executionTime': {vocsetField: 'executionTime', header: 'Trade Date'},
    'Submission Time': {vocsetField: 'giveupTime', header: 'Submission Time'}
};

export async function formatIceTrade(tradeData: { [key: string]: any }): Promise<Partial<UnvalidatedTrade>> {
    const iceTradeData: Partial<UnvalidatedTrade> = {...tradeData};
    const profile = JSON.parse(localStorage.getItem('userProfile') || '{}');
    const userCompany = profile.ownerShortName;
    const mappedData: Partial<UnvalidatedTrade> = {};

    // @ts-ignore
    if (iceTradeData['Hub']) {
        // @ts-ignore
        iceTradeData['Description'] = iceTradeData['Hub']
    }

    // @ts-ignore
    if (iceTradeData['Trade Date']) {
        // @ts-ignore
        const originalDate = new Date(iceTradeData['Trade Date'] );
        // @ts-ignore
        iceTradeData['Trade Date'] = originalDate.toISOString().split('T')[0];
        // @ts-ignore
        iceTradeData['executionTime'] = originalDate.toISOString();
    }

    // @ts-ignore
    if (iceTradeData['Submission Time']) {
        // @ts-ignore
        let originalDate = new Date(iceTradeData['Submission Time']);
        // @ts-ignore
        iceTradeData['Submission Time'] = originalDate.toISOString();
    }

    // @ts-ignore
    if (!iceTradeData['Price'] && iceTradeData['Option'] === 'put' || iceTradeData['Option'] === 'call') {
        // @ts-ignore
        iceTradeData['Price'] = iceTradeData['Premium'];
    }

    // @ts-ignore
    if (iceTradeData['Seller Clearing Acct'] || iceTradeData['Buyer Clearing Acct']) {
        let side: string | undefined;
        let client;
        let clearer;
        let clearingAccount;

        const determineSide = (item: any) => {
            item.data.data.result?.forEach((entry: { owner: string; client: string; name: string }) => {
                // @ts-ignore
                if (iceTradeData['Seller Clearing Acct'] === entry.name) {
                    client = entry.client;
                    clearer = entry.owner
                    clearingAccount = entry.name;
                    side = 'sell';
                } else { // @ts-ignore
                    if (iceTradeData['Buyer Clearing Acct'] === entry.name) {
                        client = entry.client;
                        clearer = entry.owner
                        clearingAccount = entry.name;
                        side = 'buy';
                    }
                }
            });
        };

        const storageKeys = ['axios-cache-account', 'axios-cache-alias'];
        for (const key of storageKeys) {
            const storedData = localStorage.getItem(key);
            if (storedData) {
                try {
                    const item = JSON.parse(storedData);
                    determineSide(item);
                    if (side) {
                        // @ts-ignore
                        iceTradeData['B/S'] = side;
                        break;
                    }
                } catch (e) {
                    console.error('Failed to parse JSON for key:', key, e);
                }
            }
        }

        if (!side) {
            // @ts-ignore
            const sellerAccount = await AccountService.getAccountOrAlias(iceTradeData['Seller Clearing Acct']);
            if (sellerAccount) {
                client = sellerAccount.result?.client;
                clearer = sellerAccount.result?.owner;
                clearingAccount = sellerAccount.result?.name;
                side = 'sell';
            } else {
                // @ts-ignore
                const buyerAccount = await AccountService.getAccountOrAlias(iceTradeData['Buyer Clearing Acct']);
                if (buyerAccount) {
                    client = buyerAccount.result?.client;
                    clearer = buyerAccount.result?.owner;
                    clearingAccount = buyerAccount.result?.name;
                    side = 'buy';
                }
            }

            if (side) {
                // @ts-ignore
                iceTradeData['B/S'] = side;
            }
        }

        if (clearer){
            // @ts-ignore
            iceTradeData['Clearing Firm'] = clearer;
        }

        if (client) {
            iceTradeData['client'] = client;
        }

        if (clearingAccount){
            // @ts-ignore
            iceTradeData['Clearing Acct'] = clearingAccount;
        }

    }

    if (userCompany) {
        // @ts-ignore
        iceTradeData['Broker Name'] = userCompany;
    }

    for (const iceField in iceColumns) {
        const {vocsetField} = iceColumns[iceField];
        // @ts-ignore
        if (iceTradeData[iceField] !== undefined) {
            // @ts-ignore
            mappedData[vocsetField] = iceTradeData[iceField];
        }
    }

    console.log('ice mapped ', mappedData)

    return mappedData;
}
