import React, { useState } from 'react';
import {
    Box,
    Button,
    Container,
    CssBaseline,
    TextField,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/auth.service';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const defaultTheme = createTheme();

interface PasswordConfirmationProps {
    username: string; // Username prop is required
}

const PasswordConfirmation: React.FC<PasswordConfirmationProps> = ({ username }) => {
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');
    const [resetError, setResetError] = useState<string>('');
    const [resetSuccess, setResetSuccess] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // Password Validation
        if (password !== confirmPassword) {
            setPasswordError('Passwords do not match');
            return;
        } else {
            setPasswordError('');
        }

        try {
            // Call the service method to update the password
            await AuthService.savePassword(username, password);

            // Show success dialog
            setResetSuccess(true);
        } catch (error) {
            console.error('Error updating password:', error);
            setResetError('Error updating password. Please try again.');
        }
    };

    const handleCloseDialog = () => {
        setResetSuccess(false);
        navigate('/');
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label="New Password"
                            name="password"
                            type="password"
                            value={password}
                            error={passwordError !== ''}
                            helperText={passwordError}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="confirmPassword"
                            label="Confirm New Password"
                            name="confirmPassword"
                            type="password"
                            value={confirmPassword}
                            error={passwordError !== ''}
                            helperText={passwordError}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Reset Password
                        </Button>
                        {resetError && (
                            <Typography variant="body2" color="error" align="center">
                                {resetError}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Container>
            <Dialog open={resetSuccess} onClose={handleCloseDialog}>
                <DialogTitle>Password Reset Successful</DialogTitle>
                <DialogContent>
                    <Typography>Your password has been successfully updated.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default PasswordConfirmation;
