import axios from 'axios';
import {handleRequestError} from "../errorHandler";

const REGISTER_API_URL = '/api/auth/register';

class RegisterService {
    async register(username: string, password: string) {
        try {
            const response = await axios.post(REGISTER_API_URL, { username, password });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }
}

export default new RegisterService();
