import React, {SyntheticEvent, useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import AuthService from "../services/auth.service";
import Image from 'mui-image'

const defaultTheme = createTheme();

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const handleLogin = async (e: SyntheticEvent) => {
        e.preventDefault();

        try {
            const response = await AuthService.login(username, password, "");
            // const response = await AuthService.addTOTPToken(username);
            const showQRCode = response.id === '2FRQ'; // Check if service requires OTP to be setup.
            navigate('/Authenticate', {
                state: {
                    username,
                    password,
                    qrCodeImg: showQRCode ? response.message : '', // Set QR code URL if it exists
                }
            });
        } catch (error) {
            // @ts-ignore
            if (error.hasOwnProperty('response')) {
                // @ts-ignore
                var errMsg = error.response.data.result[0].message
                console.error(errMsg);
                setError(errMsg);
            } else {
                console.error(error);
                setError('Error during authentication');
            }

        }
    };


    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Image src="vocset.png" />
                    {/*<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>*/}
                    {/*    <LockOutlinedIcon />*/}
                    {/*</Avatar>*/}
                    <Grid>
                        <Grid item xs>
                            <Typography variant="body2">
                                {"v:"}{process.env.REACT_APP_VERSION}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Email"
                            name="username"
                            autoComplete="username"
                            value={username}
                            autoFocus
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {error ? <Typography>{error}</Typography> : null}
                        {/*<FormControlLabel*/}
                        {/*    control={<Checkbox value="remember" color="primary" />}*/}
                        {/*    label="Remember me"*/}
                        {/*/>*/}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Typography component={RouterLink} to="/PasswordReset" variant="body2">
                                    {"Forgot password?"}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography component={RouterLink} to="/Register" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default Login;
