import React, {useState} from 'react';
import {
    Box,
    Button,
    Container,
    CssBaseline,
    TextField,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import AuthService from '../services/auth.service';
import {createTheme, ThemeProvider} from '@mui/material/styles';

const defaultTheme = createTheme();

const PasswordReset: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [usernameError, setUsernameError] = useState<string>('');
    const [resetError, setResetError] = useState<string>('');
    const [resetSuccess, setResetSuccess] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // Username Validation
        if (!username) {
            setUsernameError('Username is required');
            return;
        } else {
            setUsernameError('');
        }

        try {
            await AuthService.resetPassword(username);

            setResetSuccess(true);
            setUsername('');
        } catch (error) {
            console.error('Error during password reset:', error);
            setResetError('Invalid username');
        }
    };

    const handleCloseDialog = () => {
        setResetSuccess(false); // Close the pop-up message
        navigate('/'); // Navigate to the login screen
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Password Reset
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 3}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            value={username}
                            autoFocus
                            error={usernameError !== ''}
                            helperText={usernameError}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            Reset Password
                        </Button>
                        {resetError && (
                            <Typography variant="body2" color="error" align="center">
                                {resetError}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Container>
            <Dialog open={resetSuccess} onClose={handleCloseDialog}>
                <DialogTitle>Password Reset Request</DialogTitle>
                <DialogContent>
                    <Typography>An email containing a link to reset your password has been sent.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default PasswordReset;
