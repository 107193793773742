import {UnvalidatedTrade} from "../../../types/APITypes";
import AccountService from '../../../services/account.service';

export const cmeColumns: { [key: string]: { vocsetField: string, header?: string, entityName?: string } } = {
    'Trade ID': {vocsetField: 'tradeID', header: 'TradeID'},
    'Trade Date': {vocsetField: 'tradeDate', header: 'Trade Date'},
    'side': {vocsetField: 'side', header: 'Side'},
    'Cleared Contracts': {vocsetField: 'quantity', header: 'Qty', entityName: 'number'},
    'Price': {vocsetField: 'price', header: 'Price', entityName: 'number'},
    'CC': {vocsetField: 'instrumentCode', header: 'Instrument', entityName: 'Contract'},
    'Description': {vocsetField: 'maturity', header: 'Maturity'},
    'CH': {vocsetField: 'mic', header: 'Exchange', entityName: 'Exchange'},
    'client': {vocsetField: 'client', header: 'Client', entityName: 'Client'},
    'executingBroker': {vocsetField: 'executingBroker', header: 'Broker', entityName: 'Executing Broker'},
    'executingAccount': {vocsetField: 'executingAccount', header: 'Ex Account', entityName: 'Account'},
    'Product': {vocsetField: 'productDescription', header: 'Description'},
    'optionType': {vocsetField: 'optionType', header: 'Put/Call'},
    'strike': {vocsetField: 'strike', header: 'Strike', entityName: 'number'},
    'clearingAccount': {vocsetField: 'clearingAccount', header: 'Clr Account', entityName: 'Account'},
    'clearingBroker': {vocsetField: 'clearingBroker', header: 'Clearer', entityName: 'Clearing Broker'},
    'Status Msg': {vocsetField: 'comment', header: 'Comment'},
    'Exec Time': {vocsetField: 'executionTime', header: 'Exec Time'},
    'Created Time': {vocsetField: 'giveupTime', header: 'Created Time'}
};

export async function formatCmeTrade(tradeData: { [key: string]: any }): Promise<Partial<UnvalidatedTrade>> {
    const cmeTradeData: Partial<UnvalidatedTrade> = {...tradeData};
    const profile = JSON.parse(localStorage.getItem('userProfile') || '{}');
    const userCompany = profile.ownerShortName;
    const mappedData: Partial<UnvalidatedTrade> = {};
    const regex = /^(\D{3})(\d{2})\s(\d+\.\d+)\s(\w+)$/;

    const numericFields = ['Trade ID', 'Cleared Contracts', 'Price', 'strike'];

    numericFields.forEach(field => {
        // @ts-ignore
        if (cmeTradeData[field] !== undefined) {
            // @ts-ignore
            cmeTradeData[field] = String(cmeTradeData[field]);
        }
    });

    // @ts-ignore
    if (cmeTradeData['CH'] && cmeTradeData['CC']){
        // @ts-ignore
        cmeTradeData['CH'] = `${cmeTradeData['CC']}:${cmeTradeData['CH']}`;
    }

    // @ts-ignore
    if (cmeTradeData['Created Time']) {
        // @ts-ignore
        let originalDate = new Date(cmeTradeData['Created Time']);
        // @ts-ignore
        cmeTradeData['Created Time'] = originalDate.toISOString();
    }

    // @ts-ignore
    if (cmeTradeData['Exec Time'] && cmeTradeData['Trade Date']) {
        // @ts-ignore
        const tradeDate = new Date(cmeTradeData['Trade Date']);
        // @ts-ignore
        const execTime = new Date(cmeTradeData['Exec Time']);
        // @ts-ignore
        cmeTradeData['Exec Time'] = new Date(
            `${tradeDate.toISOString().split('T')[0]}T${execTime.toTimeString().split(' ')[0]}`).toISOString();
        // @ts-ignore
        cmeTradeData['Trade Date'] = tradeDate.toISOString().split('T')[0];
    }


    // @ts-ignore
    if (cmeTradeData['Description']) {
        // @ts-ignore
        const match = cmeTradeData['Description'].match(regex);
        if (match) {
            const month = match[1];
            const year = match[2];
            cmeTradeData['strike'] = match[3];
            cmeTradeData['optionType'] = match[4];

            const monthMapping: { [key: string]: string } = {
                Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05',
                Jun: '06', Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
            };

            const monthNumber = monthMapping[month];
            // @ts-ignore
            cmeTradeData['Description'] = `20${year}-${monthNumber}-01`;
        }
    }

    // @ts-ignore
    if (cmeTradeData['S Acct'] || cmeTradeData['B Acct']) {
        let side: string | undefined;
        let client;
        let clearer;
        let clearingAccount;

        const determineSide = (item: any) => {
            item.data.data.result?.forEach((entry: {
                owner: string;
                client: string;
                name: string;
            }) => {
                // @ts-ignore
                if (cmeTradeData['S Acct'] === entry.name) {
                    client = entry.client;
                    clearer = entry.owner
                    clearingAccount = entry.name;
                    side = 'sell';
                } else {
                    // @ts-ignore
                    if (cmeTradeData['B Acct'] === entry.name) {
                        client = entry.client;
                        clearer = entry.owner
                        clearingAccount = entry.name;
                        side = 'buy';
                    }
                }
            });
        };

        const storageKeys = ['axios-cache-account', 'axios-cache-alias'];
        for (const key of storageKeys) {
            const storedData = localStorage.getItem(key);
            if (storedData) {
                try {
                    const item = JSON.parse(storedData);
                    determineSide(item);
                    if (side) {
                        cmeTradeData['side'] = side;
                        break;
                    }
                } catch (e) {
                    console.error('Failed to parse JSON for key:', key, e);
                }
            }
        }

        if (!side) {
            // @ts-ignore
            const sellerAccount = await AccountService.getAccountOrAlias(cmeTradeData['S Acct']);
            if (sellerAccount) {
                client = sellerAccount.result?.client;
                clearer = sellerAccount.result?.owner;
                clearingAccount = sellerAccount.result?.name;
                side = 'sell';
            } else {
                // @ts-ignore
                const buyerAccount = await AccountService.getAccountOrAlias(cmeTradeData['B Acct']);
                if (buyerAccount) {
                    client = buyerAccount.result?.client;
                    clearer = buyerAccount.result?.owner;
                    clearingAccount = buyerAccount.result?.name;
                    side = 'buy';
                }
            }

            if (side) {
                cmeTradeData['side'] = side;
            }
        }

        if (clearer) {
            cmeTradeData['clearingBroker'] = clearer;
        }

        if (client) {
            cmeTradeData['client'] = client;
        }

        if (clearingAccount){
            cmeTradeData['clearingAccount'] = clearingAccount;
        }
    }

    cmeTradeData['executingBroker'] = userCompany;

    for (const cmeField in cmeColumns) {
        const {vocsetField} = cmeColumns[cmeField];
        // @ts-ignore
        if (cmeTradeData[cmeField] !== undefined) {
            // @ts-ignore
            mappedData[vocsetField] = cmeTradeData[cmeField];
        }
    }

    console.log('cme mapped ', mappedData)

    return mappedData;
}
