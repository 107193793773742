import React, {useState} from 'react';
import {
    Box,
    Button,
    CssBaseline,
    TextField,
    Typography,
    Dialog,
    DialogContent
} from '@mui/material';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {dropdownItems} from '../../views/dropdownItems';
import VOCSETLayout from "../../views/VOCSETLayout";
import {Grid} from '@mui/material';
import ProductService from "../../services/product.service";

interface EditProductProps {
    onSuccess: () => void;
}

const EditProduct: React.FC<EditProductProps> = ({onSuccess}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const product = location.state || {};

    const [productState, setProductState] = useState(product);
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [action, setAction] = useState<'save' | 'delete' | null>(null);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/ProductDashboard');
    };

    const handleSave = async () => {
        try {
            await ProductService.updateProduct(productState);
            onSuccess();
            setAction('save');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            console.error('Error updating product:', error);
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error updating product. Please try again.');
        }
    };

    let dialogContent = '';
    if (action === 'save') {
        dialogContent = 'Update complete';
    }

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{alignSelf: 'flex-start'}}>
                        Edit Product
                    </Typography>
                    <Box component="form" noValidate sx={{mt: 3}}>
                        <Grid container spacing={2} sx={{paddingBottom: 4}}>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="contractCode"
                                    label="Contract Code"
                                    value={productState.contractCode}
                                    onChange={(e) => setProductState({ ...productState, contractCode: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="mic"
                                    label="MIC"
                                    value={productState.mic}
                                    disabled
                                    onChange={(e) => setProductState({...productState, mic: e.target.value})}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="description"
                                    label="Description"
                                    value={productState.description}
                                    onChange={(e) => setProductState({...productState, description: e.target.value})}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="underlier"
                                    label="Underlier"
                                    value={productState.underlier}
                                    onChange={(e) => setProductState({...productState, underlier: e.target.value})}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                onClick={handleSave}
                                variant="contained"
                                sx={{width: 'auto', mb: 2}}
                            >
                                Save
                            </Button>
                            <Button
                                component={Link}
                                to="/ProductDashboard"
                                variant="contained"
                                color="primary"
                                sx={{width: 'auto', mb: 2, marginLeft: 3}}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        {error && (
                            <Typography variant="body2" color="error">
                                {error}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">{dialogContent}</Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default EditProduct;