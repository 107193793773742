export function handleRequestError(error: any) {
    if (error.response && error.response.status === 401) {
        console.log(error)
        localStorage.clear();
        window.location.href = '/';
    } else {
        console.error("Error occurred:", error);
    }
    throw error;
}
