import React, {useEffect, useState} from 'react';
import {
    Box, Button, CssBaseline, TextField, Typography, Dialog,
    DialogContent, Select, MenuItem, Grid
} from '@mui/material';
import {Link, useNavigate} from "react-router-dom";
import {dropdownItems} from "../../views/dropdownItems";
import VOCSETLayout from '../../views/VOCSETLayout';
import AccountService from "../../services/account.service";
import AssociationService from '../../services/association.service';
import {Association} from "../../types/APITypes";

function CreateClrAccount() {
    const [name, setName] = useState('');
    const [owner, setOwner] = useState('');
    const [client, setClient] = useState('');
    const [active, setActive] = useState(true);
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [clientOptions, setClientOptions] = useState<Association[]>([]);
    const [ownerOptions, setOwnerOptions] = useState<Association[]>([]);
    const [selectedClient, setSelectedClient] = useState<Association | null>(null);
    const userProfile = JSON.parse(localStorage.getItem('userProfile') || '{}');
    const navigate = useNavigate();

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/AccountDashboard')
    };

    useEffect(() => {
        AssociationService.getAssociations()
            .then((fetchedData) => {
                const filteredOptions = fetchedData.filter(
                    (option: {
                        associateShortName: string;
                        associationTypeName: string;
                    }) =>
                        option.associationTypeName === 'clearsWith'
                );
                setClientOptions(filteredOptions);
            })
            .catch((error) => {
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching client options. Please try again.');
            });
    }, []);

    const handleSubmit = async (event: {
        preventDefault: () => void;
    }) => {
        event.preventDefault();

        const role = {
            id: 'ROLE_CLEARING_ACCOUNT',
            name: 'CLEARING_ACCOUNT'
        };

        try {
            const accountData = {
                name,
                owner,
                client,
                active,
                role
            };
            
            await AccountService.createAccount(accountData);
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error creating account. Please try again.');
        }
    };

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%'
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{alignSelf: 'flex-start'}}>
                        Create Account
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 3, width: '100%'}}>
                        <Grid container spacing={2} sx={{paddingBottom: 4}}>
                            <Grid item xs={4}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    sx={{marginTop: '16px'}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    fullWidth
                                    id="client"
                                    value={selectedClient?.principalShortName || ''}
                                    onChange={(e) => {
                                        const selected = clientOptions
                                            .find(option => option.principalShortName === e.target.value);
                                        setSelectedClient(selected || null);
                                        if (selected) {
                                            setClient(selected.principalShortName)
                                            const filteredOwners = clientOptions.filter(
                                                option =>
                                                    option.principalShortName === selected.principalShortName &&
                                                    option.associationTypeName === 'clearsWith'
                                            );
                                            setOwnerOptions(filteredOwners);
                                        } else {
                                            setOwnerOptions([]);
                                        }
                                    }}
                                    renderValue={(selected) => (selected === '' ? 'Select Client' : selected)}
                                    displayEmpty
                                    sx={{marginTop: '16px'}}
                                    disabled={!clientOptions.length}
                                >
                                    {Array.from(new Set(clientOptions.map(option => option.principalShortName)))
                                        .map((principalShortName, index) => (
                                            <MenuItem key={index} value={principalShortName}>
                                                {principalShortName}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    fullWidth
                                    id="owner"
                                    value={owner || ''}
                                    onChange={(e) => {
                                        setOwner(e.target.value as string);
                                    }}
                                    renderValue={(selected) => (selected === '' ? 'Select Owner' : selected)}
                                    displayEmpty
                                    sx={{marginTop: '16px'}}
                                    disabled={!ownerOptions.length}
                                >
                                    {ownerOptions.map((option, index) => (
                                        <MenuItem key={index} value={option.associateShortName}>
                                            {option.associateShortName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{width: 'auto', mb: 2}}
                                    disabled={clientOptions.length === 0 || !owner}
                                >
                                    Save
                                </Button>
                                <Button
                                    component={Link}
                                    to="/AccountDashboard"
                                    variant="contained"
                                    color="primary"
                                    sx={{width: 'auto', mb: 2, marginLeft: 3}}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    {error && (
                        <Typography variant="body2" color="error" sx={{ alignSelf: 'flex-start' }}>
                            {error}
                        </Typography>
                    )}
                </Box>
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">Account created successfully!</Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default CreateClrAccount;