import React, {useState, useEffect} from 'react';
import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Tab,
    Button, ListItem, ListItemIcon, ListItemText, ButtonBase, ButtonGroup, DialogContent, Typography, Dialog
} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import AliasService from '../../services/alias.service';
import Table from '@mui/material/Table';
import {Link, useLocation} from 'react-router-dom';
import VOCSETLayout from '../../views/VOCSETLayout';
import {dropdownItems} from '../../views/dropdownItems';
import {MoonLoader} from "react-spinners";
import {Alias} from "../../types/APITypes";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";

createTheme();

const AliasDashboard = () => {
    const [aliases, setAliases] = useState<Alias[]>([]);
    const [error, setError] = useState<string>('');
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(true);
    const [selectedEntityName, setSelectedEntityName] = useState<string>('');
    const [privileges, setPrivileges] = useState<string[]>([]);
    const [userRoles, setUserRoles] = useState<string[]>([]);
    const location = useLocation();
    const entityMap: Record<string, string> = {
        'Contract': 'contractCode',
        'Account': 'accountName',
        'Exchange': 'micCode',
        'Company': 'companyShortName'
    };
    const [openDialog, setOpenDialog] = useState(false);
    const [action, setAction] = useState<'delete' | 'error' | null>(null);

    const resize = () => {
        setOpen(window.innerWidth >= 760);
    };

    useEffect(() => {
        window.addEventListener('resize', resize);
        resize();
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);

    useEffect(() => {
        const storedPrivileges = localStorage.getObj('privileges');
        if (storedPrivileges) {
            setPrivileges(storedPrivileges);
        }
    }, []);

    useEffect(() => {
        const userProfile = JSON.parse(localStorage.getItem('userProfile') || '{}');
        const roles = userProfile?.roles?.map((role: { name: string }) => role.name) || [];
        setUserRoles(roles);
    }, []);

    useEffect(() => {
        const fetchAliases = async () => {
            try {
                const response = await AliasService.findAliases();
                setLoading(false);
                const fetchedAliases = response.result;
                if (fetchedAliases && fetchedAliases.length > 0) {
                    const updatedAliases = fetchedAliases.map((alias: {
                        [x: string]: any;
                        type: string | number;
                    }) => ({
                        ...alias,
                        value: alias[entityMap[alias.type]]
                    }));
                    setAliases(updatedAliases);
                    const passedEntityName = location.state?.selectedEntityName;
                    const entityNameToSet = passedEntityName && updatedAliases.some((alias: Alias) => alias.type === passedEntityName)
                        ? passedEntityName
                        : updatedAliases[0].type;
                    setSelectedEntityName(entityNameToSet);
                } else {
                    setError('No aliases available.');
                }
            } catch (error) {
                // @ts-ignore
                console.error('Error fetching aliases:', error.response ? error.response.data : error);
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching aliases. Please try again.');
            }
        };

        fetchAliases();
    }, [location.state]);

    let dialogContent = '';
    if (action === 'delete') {
        dialogContent = 'Deletion complete';
    } else if (action === 'error') {
        dialogContent = error;
    }

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDelete = async (type: string, id: string) => {
        try {
            await AliasService.deleteAlias(type, id);
            setAction('delete');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error: any) {
            const errorMessage = error?.response?.data?.result?.[0]?.message || 'Error deleting alias. Please try again.';
            setError(errorMessage);
            setAction('error');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        }
    };

    const uniqueEntityNames = Array.from(new Set(aliases.map(alias => alias.type)));

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                {privileges.includes('CREATE_ANY_ALIAS') && (
                    <ButtonGroup>
                        <Button aria-label="New Alias" variant="contained" component={Link} to="/CreateAlias"
                                startIcon={<AddIcon/>}>New Alias</Button>
                    </ButtonGroup>
                )}
                <Tabs
                    value={selectedEntityName}
                    onChange={(event, newValue) => setSelectedEntityName(newValue)}
                    aria-label="Alias Tabs"
                >
                    {uniqueEntityNames.map((entityName, index) => (
                        <Tab key={index} label={entityName} value={entityName}/>
                    ))}
                </Tabs>
                {loading ? (
                    <MoonLoader color="#282c34"/>
                ) : (
                    aliases.length === 0 ? (
                        <p>No aliases found.</p>
                    ) : (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Value</TableCell>
                                        <TableCell>Alias</TableCell>
                                        {(userRoles.includes('SUPERUSER') || userRoles.includes('ADMIN')) && (
                                            <TableCell>Action</TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {aliases.filter(alias => alias.type === selectedEntityName).map(alias => (
                                        <TableRow key={alias.id}>
                                            <TableCell>{alias.value}</TableCell>
                                            <TableCell>{alias.alias}</TableCell>
                                            {(userRoles.includes('SUPERUSER') || userRoles.includes('ADMIN')) && (
                                                <TableCell>
                                                    <ButtonGroup className="dash-button-group">
                                                        <Link to={`/EditAlias`} state={alias}>
                                                            <Button className="dash-button" startIcon={<EditIcon/>}/>
                                                        </Link>
                                                        <Button
                                                            className="dash-button"
                                                            startIcon={<DeleteIcon/>}
                                                            onClick={() => handleDelete(alias.type, alias.id)}
                                                        />
                                                    </ButtonGroup>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                )}
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">{dialogContent}</Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AliasDashboard;
