import axios from 'axios';
import authHeader from './auth.header';
import {handleRequestError} from "../errorHandler";
import {Exchange, Trade, UnvalidatedTrade} from '../types/APITypes'

const TRADE_SERVICE_URL = '/api/trade';
const ctype = {'Content-Type': 'application/json'}

class TradeService {
    async getTrades() {
        try {
            const response = await axios.get(TRADE_SERVICE_URL,
                {
                    headers: {...authHeader(), ...ctype}
                });
            return response.data
        } catch (error) {
            handleRequestError(error);
        }
    }

    async postNewTrades(data: Array<UnvalidatedTrade>) {
        try {
            const response = await axios.post(TRADE_SERVICE_URL, data,
                {
                    headers: {...authHeader(), ...ctype}
                });
            console.log("trade response ", response.data);
            return response.data
        } catch (error) {
            console.error("Error occurred:", error);
            throw error;
        }
    }

    async getExchanges() {
        try {
            return await axios.get<Array<Exchange>>(TRADE_SERVICE_URL,
                {
                    headers: {...authHeader(), ...ctype}
                });
        } catch (error) {
            handleRequestError(error);
        }
    }
}

var tradeService = new TradeService()
export default tradeService;
