import axios from 'axios';
import { handleRequestError } from '../errorHandler';
import authHeader from "./auth.header";
import axiosCache from '../components/cache/axiosCache'

const SDM_SERVICE_URL = '/api/sdm';
const ctype = { 'Content-Type' : 'application/json'}

class ProductService {
    async getProducts() {
        try {
            const response = await axiosCache.get(`${SDM_SERVICE_URL}/product`,
                { headers: {...authHeader(), ...ctype}, id: 'product'
                });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

    async updateProduct(productData: {
        contractCode: string;
        mic: string;
        description: string;
        underlier: string;
    }) {
        try {
            const response = await axios.post(`${SDM_SERVICE_URL}/updateProduct`, productData,
                { headers: {...authHeader(), ...ctype}
                });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

}

export default new ProductService();
