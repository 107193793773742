import React, {useState, useEffect} from 'react';
import {
    Box,
    Button,
    CssBaseline,
    TextField,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Grid,
    IconButton
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {MoonLoader} from 'react-spinners';
import {v4 as uuidv4} from 'uuid';
import APIService from '../../services/api.service';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {dropdownItems} from '../../views/dropdownItems';
import VOCSETLayout from '../../views/VOCSETLayout';

interface CreateAPIKeyProps {
    onSuccess: () => void;
}

const CreateAPIKey: React.FC<CreateAPIKeyProps> = ({onSuccess}) => {
    const [apiKey, setAPIKey] = useState('');
    const [apiSecret, setAPISecret] = useState('');
    const [apiLabel, setAPILabel] = useState('');
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [totpDialogOpen, setTotpDialogOpen] = useState(false);
    const [totpCode, setTotpCode] = useState('');
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setAPIKey(uuidv4());
        setAPISecret(uuidv4());
        setLoading(false);
        setAPILabel(location.state.apiLabel || '');
        console.log("state ", location.state)
    }, [location.state]);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/APIDashboard');
    };

    const handleSave = async () => {
        setTotpDialogOpen(true);
    };

    const handleAPICreateRequest = async () => {
        try {
            const updatedUserAPIData = {
                totpCode,
                apiKey,
                apiSecret,
                apiLabel,
            };

            await APIService.createAPIKey(updatedUserAPIData);
            onSuccess();
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            // @ts-ignore
            const status = error.response?.status;
            // @ts-ignore
            const result = error.response?.data.result;
            if (status === 400 && result?.[0]?.id === 'CONF') {
                setError('Users must have an assigned company in order to create an API Key.');
            } else {
                console.error(result?.[0]?.message);
                setError(result?.[0]?.message);
            }
        } finally {
            setTotpDialogOpen(false);
        }
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            console.log('Copied to clipboard:', text);
        }).catch(err => {
            console.error('Could not copy text:', err);
        });
    };

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <CssBaseline/>
                <Box sx={{marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Typography component="h1" variant="h5" sx={{alignSelf: 'flex-start'}}>
                        Create API Key
                    </Typography>
                    {loading ? (
                        <MoonLoader color="#282c34"/>
                    ) : (
                        <Box component="form" noValidate sx={{mt: 3}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        id="apiLabel"
                                        label="API Label"
                                        value={apiLabel}
                                        disabled={location.state.apiLabel}
                                        onChange={(e) => setAPILabel(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>API Key:</Typography>
                                    <Typography gutterBottom>{apiKey}
                                    <IconButton onClick={() => copyToClipboard(apiKey)}>
                                        <ContentCopyIcon />
                                    </IconButton>Copy</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>API Secret:</Typography>
                                    <Typography gutterBottom>{apiSecret}
                                    <IconButton onClick={() => copyToClipboard(apiSecret)}>
                                        <ContentCopyIcon />
                                    </IconButton>Copy</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        ** Please make a note of your secret as it will not be visible after creation.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        onClick={handleSave}
                                        variant="contained"
                                        sx={{width: 'auto', mb: 2}}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        component={Link}
                                        to="/APIDashboard"
                                        variant="contained"
                                        color="primary"
                                        sx={{width: 'auto', mb: 2, marginLeft: 3}}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                            {error && (
                                <Typography variant="body2" color="error">
                                    {error}
                                </Typography>
                            )}
                        </Box>
                    )}
                </Box>
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">API Key created successfully</Typography>
                </DialogContent>
            </Dialog>
            <Dialog open={totpDialogOpen} onClose={() => setTotpDialogOpen(false)}>
                <DialogContent>
                    <Typography variant="h6">OTP Code Authentication</Typography>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="totpCode"
                        label="Verify OTP Code"
                        value={totpCode}
                        onChange={(e) => setTotpCode(e.target.value)}
                    />
                    <div>Enter the 6-digit code generated by the Authenticator App.</div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAPICreateRequest} variant="contained">
                        Submit
                    </Button>
                    <Button onClick={handleCloseDialog} variant="contained">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CreateAPIKey;
