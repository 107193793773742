import React, {useState, useEffect} from 'react';
import {
    ButtonGroup, Dialog, DialogContent, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import AccountService from '../../services/account.service';
import Table from '@mui/material/Table';
import {Link} from 'react-router-dom';
import Button from "@mui/material/Button";
import VOCSETLayout from '../../views/VOCSETLayout';
import {dropdownItems} from '../../views/dropdownItems';
import {MoonLoader} from "react-spinners";
import {Account, Company} from "../../types/APITypes";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import CompanyService from "../../services/company.service";


createTheme();
const AccountDashboard = () => {
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [error, setError] = useState<string>('');
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(true);
    const [privileges, setPrivileges] = useState<string[]>([]);
    const [action, setAction] = useState<'activate' | 'delete' | 'error' | null>(null);
    const [openDialog, setOpenDialog] = useState(false);

    const resize = () => {
        setOpen(window.innerWidth >= 760);
    };
    const handleDrawerToggle = () => {
        setOpen(!open);
    };
    useEffect(() => {
        // @ts-ignore
        window.addEventListener('resize', resize.bind(this));
        resize();
    }, []);

    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'Script error.') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                )
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                )
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        })
    }, [])

    useEffect(() => {
        const storedPrivileges = localStorage.getObj('privileges');
        if (storedPrivileges) {
            setPrivileges(storedPrivileges);
        }
    }, []);

    useEffect(() => {
        const fetchAccounts = async () => {
            try {
                const response = await AccountService.getAccounts();
                if (response) {
                    setLoading(false)
                }
                const res = response.result;
                if (res && res.length > 0) {
                    setAccounts(res);
                } else {
                    setError('No accounts available.');
                }
            } catch (error) {
                // @ts-ignore
                console.error('Error fetching accounts:', error.response ? error.response.data : error);
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching accounts. Please try again.');
            }
        };

        fetchAccounts();
    }, []);

    const handleActivation = async (account: Account) => {
        try {
            const updatedAccountData = {
                ...account,
                active: true,
            };

            await AccountService.updateAccount(updatedAccountData);

            setAction('activate');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);

        } catch (error: any) {
            console.error('Error activating account:', error);
            const errorMessage = error?.response?.data?.result?.[0]?.message || 'Error activating account. Please try again.';
            setError(errorMessage);
            setAction('error');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        }
    };


    let dialogContent = '';
    if (action === 'activate') {
        dialogContent = 'Activation complete';
    } else if (action === 'delete') {
        dialogContent = 'Deactivation complete';
    } else if (action === 'error') {
        dialogContent = error;
    }

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDelete = async (name: string) => {
        try {
            await AccountService.deleteAccount(name);
            setAction('delete');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error deleting account. Please try again.');
            setAction('error');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        }
    };

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                {(privileges.includes('CREATE_ANY_ACCOUNT') || privileges.includes('CREATE_OWN_ACCOUNT')) && (
                    <ButtonGroup style={{gap: '10px'}}>
                        <Button aria-label="New Clearing Account" variant="contained" component={Link}
                                to="/CreateClrAccount" startIcon={<AddIcon/>}>New ClrAccount</Button>
                        &nbsp;
                        <Button aria-label="New Executing Account" variant="contained" component={Link}
                                to="/CreateExecAccount" startIcon={<AddIcon/>}>New ExecAccount</Button>
                    </ButtonGroup>
                )}
                {loading ? (
                    <MoonLoader color="#282c34"/>
                ) : (
                    accounts.length === 0 ? (
                        <p>No accounts found.</p>
                    ) : (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Owner</TableCell>
                                        <TableCell>Client</TableCell>
                                        {privileges.includes('WRITE_ANY_ACCOUNT') && (
                                            <TableCell>Actions</TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {accounts.map((acc) => (
                                        <TableRow key={acc.id}
                                                  style={{backgroundColor: acc.active ? '' : '#f5f5f5'}}>
                                            <TableCell>{acc.name}</TableCell>
                                            <TableCell>{acc.owner}</TableCell>
                                            <TableCell>{acc.client}</TableCell>
                                            {privileges.includes('WRITE_ANY_ACCOUNT') && (
                                                <TableCell>
                                                    <ButtonGroup className="dash-button-group">
                                                        <Link to={`/EditAccount`} state={acc}>
                                                            <Button className="dash-button" startIcon={<EditIcon/>}/>
                                                        </Link>
                                                        {acc.active ? (
                                                            <Button
                                                                className="dash-button"
                                                                startIcon={<DeleteIcon/>}
                                                                onClick={() => handleDelete(acc.name)}
                                                            />
                                                        ) : (
                                                            <Button
                                                                className="dash-button"
                                                                startIcon={<DoneIcon/>}
                                                                onClick={() => handleActivation(acc)}
                                                            >
                                                            </Button>
                                                        )}
                                                    </ButtonGroup>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                )}
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">{dialogContent}</Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default AccountDashboard;


